import { accountPlanCode, customTableHeaderType, mypageTabName } from "../types";
// プログラム内の固定の変数はここで定義する
// 変数名はconfig.txと重複しては行けない

export const ROUTE_PATH = {
  BASE: "/",
  LOGIN: "/login",
  LOGIN_CALLBACK: "/login-callback",
  LOGOUT_CALLBACK: "/logout-callback",
  LOGIN_OTP: "/login-otp",
  INVITE: "/invite",
  INCLUDED_CONTENTS: "/included-contents",
  INQUIRY_BOX_USER: "/inquiry-box",
  INQUIRY_BOX_DM: "/inquiry-box-dm",
  INQUIRY_BOX_ACCOUNT: "/inquiry-box-account",
  INQUIRY_ONE: "/inquiry/:inquiryId",
  // 旧質問BOX
  QUESTION_BOX_OLD: "/question-box",
  QUESTION_BOX_ONE_OLD: "/question-box-dm/:inquiryId",
  NEW_QUESTION_OLD: "/study/contents/:contentsId/question",
  QUESTION_BOX_ACCOUNT_OLD: "/question-box-account",
  MYPAGE: "/mypage",
  CATEGORY: "/study/category/:categoryId",
  CAREER: "/study/career/:careerType/:level",
  THEME: "/study/theme/:themeId",
  CONTENTS: "/study/contents/:contentsId",
  // オリジナル
  CATEGORY_ORIGINAL: "/original/category/:categoryId",
  THEME_ORIGINAL: "/original/theme/:themeId",
  CONTENTS_ORIGINAL: "/original/contents/:contentsId",
  CONTENTS_TEST_ORIGINAL: "/original/contents/:contentsId/test",
  CONTENTS_TEST_COMPLETED_ORIGINAL: "/original/contents/:contentsId/test/completed",
  CONTENTS_TEST: "/study/contents/:contentsId/test",
  CONTENTS_TEST_COMPLETED: "/study/contents/:contentsId/test/completed",
  STUDY_STATUS: "/study-status",
  STUDY_STATUS_DM: "/study-status-dm/:accountId",
  STUDY_STATUS_USER: "/study-status/:userId",
  STUDY_STATUS_THEME: "/study-status/:userId/theme/:commonOriginalThemeId",
  STUDY_STATUS_DTL: "/study-status-dtl",
  STUDY_STATUS_DTL_DM: "/study-status-dtl-dm/:accountId",
  STUDY_STATUS_CATEGORY_TEST_HISTORY: "/study-status/:userId/category-test-history",
  STUDY_STATUS_CATEGORY_TEST_HISTORY_RESULT: "/study-status/:userId/category-test-history/:testResultCategoryId",
  ACCOUNT: "/account",
  CORPORATION: "/corporation",
  USER: "/user",
  USER_DM: "/account/:accountId/user",
  CORPORATION_USER_DM: "/corporation/:accountId/user",
  CONTENTS_MANAGEMENT: "/contents-management",
  CONTENTS_MANAGEMENT_ORIGINAL: "/contents-management-original/:accountId",
  CREATE_CONTENTS: "/contents-management/contents/create",
  CREATE_CONTENTS_ORIGINAL: "/contents-management-original/:accountId/contents/create",
  EDIT_CONTENTS: "/contents-management/contents/:contentsId",
  EDIT_CONTENTS_ORIGINAL: "/contents-management-original/:accountId/contents/:contentsId",
  EDIT_WORK_CONTENTS: "/contents-management/contents/work/:contentsWorkId",
  EDIT_WORK_CONTENTS_ORIGINAL: "/contents-management-original/:accountId/contents/work/:contentsWorkId",
  MEDIA_UPLOAD: "/media-upload",
  MEDIA_UPLOAD_ORIGINAL: "/media-upload-original/:accountId",
  CUSTOM_LIST: "/custom-list/:from",
  CATEGORY_TEST: "/category-test/:testTemplateCategoryId",
  CATEGORY_TEST_RESULT: "/category-test-result/:testResultCategoryId",
  CATEGORY_TEST_HISTORY: "/category-test-history",
  CATEGORY_TEST_HISTORY_RESULT: "/category-test-history/:testResultCategoryId",
  LEGAL_CURRICULUM_MST_LIST: "/legal-curriculum-mst/list",
  LEGAL_CURRICULUM_MST_LIST_DM: "/legal-curriculum-mst/list/:accountId",
  LEGAL_CURRICULUM_MST_CREATE: "/legal-curriculum-mst/detail/create",
  LEGAL_CURRICULUM_MST_CREATE_DM: "/legal-curriculum-mst/detail/:accountId/create",
  LEGAL_CURRICULUM_MST: "/legal-curriculum-mst/detail/:legalCurriculumMstId",
  LEGAL_CURRICULUM_MST_DM: "/legal-curriculum-mst/detail/:accountId/:legalCurriculumMstId",
  LEGAL_CURRICULUM_LIST: "/legal-curriculum",
  CAREER_EXCLUSION_DM: "/career-exclusion/:accountId",
  REPORT_PREVIEW: "/report-preview/:id",
  ANNOUNCE_MST_LIST: "/announce-mst/list",
  ANNOUNCE_MST_CREATE: "/announce-mst/detail/create",
  ANNOUNCE_MST: "/announce-mst/detail/:announceMstId",
  ANNOUNCE_LIST: "/announce/list",
  ANNOUNCE: "/announce/:announceId/:announceTarget",
  USERDATA_POLICY: "/userdata-transmission-policy",
  USER_INFO: "/user-info",
  USER_IMPORT_HISTORY: "/user/import-history",
  USER_IMPORT_HISTORY_DM: "/account/:accountId/user/import-history",
  TUTORIAL: "/tutorial",
  USER_NAME: "/user-name",
  STUDY_HISTORY: "/study-history",
  CAREER_MST: "/career-mst",
  PACKAGE: "/package/:packageId",
  PACKAGE_MANAGEMENT: "/package-management",
  PACKAGE_CONTENTS: "/package/:packageId/contents/:contentsId",
  PACKAGE_CONTENTS_TEST: "/package/:packageId/contents/:contentsId/test",
  PACKAGE_CONTENTS_TEST_COMPLETED: "/package/:packageId/contents/:contentsId/test/completed",
  GROUP_STUDY: "/group-study/:groupStudyULID",
};

// google analyticsに連携するためのページ情報を保持
// ROUTE_PATHに追加した場合はここにも追加する
export const ROUTE_PATH_INFO: Record<keyof typeof ROUTE_PATH, { title: string }> = {
  BASE: { title: "トップページ" },
  LOGIN: { title: "ログイン" },
  LOGIN_CALLBACK: { title: "ログイン" },
  LOGOUT_CALLBACK: { title: "ログアウト" },
  LOGIN_OTP: { title: "ログイン" },
  INVITE: { title: "招待" },
  INCLUDED_CONTENTS: { title: "収録コンテンツ一覧" },
  INQUIRY_BOX_USER: { title: "質問BOX" },
  INQUIRY_BOX_ACCOUNT: { title: "質問状況" },
  INQUIRY_BOX_DM: { title: "質問・問い合わせ管理" },
  QUESTION_BOX_OLD: { title: "質問BOX" },
  INQUIRY_ONE: { title: "質問BOX" },
  QUESTION_BOX_ONE_OLD: { title: "DM質問箱BOX-質問詳細" },
  QUESTION_BOX_ACCOUNT_OLD: { title: "質問状況" },
  MYPAGE: { title: "マイページ" },
  CATEGORY: { title: "カテゴリー" },
  CAREER: { title: "キャリア" },
  CATEGORY_ORIGINAL: { title: "カテゴリー（施設オリジナル）" },
  CONTENTS: { title: "コンテンツ" },
  THEME: { title: "テーマ" },
  THEME_ORIGINAL: { title: "テーマ（施設オリジナル）" },
  CONTENTS_TEST: { title: "コンテンツテスト" },
  CONTENTS_ORIGINAL: { title: "コンテンツ（施設オリジナル）" },
  CONTENTS_TEST_COMPLETED: { title: "コンテンツテスト結果" },
  CONTENTS_TEST_ORIGINAL: { title: "コンテンツテスト（施設オリジナル）" },
  CONTENTS_TEST_COMPLETED_ORIGINAL: { title: "コンテンツテスト結果（施設オリジナル）" },
  NEW_QUESTION_OLD: { title: "質問BOX(新規)" },
  STUDY_STATUS: { title: "進捗ポータル" },
  STUDY_STATUS_DM: { title: "進捗ポータル（DM管理者）" },
  STUDY_STATUS_USER: { title: "進捗ポータルユーザー" },
  STUDY_STATUS_THEME: { title: "進捗ポータルテーマ" },
  STUDY_STATUS_DTL: { title: "進捗詳細表示" },
  STUDY_STATUS_DTL_DM: { title: "進捗詳細表示（DM管理者）" },
  STUDY_STATUS_CATEGORY_TEST_HISTORY: { title: "カテゴリーテスト履歴" },
  STUDY_STATUS_CATEGORY_TEST_HISTORY_RESULT: { title: "カテゴリーテスト履歴結果" },
  ACCOUNT: { title: "アカウント" },
  CORPORATION: { title: "法人管理" },
  USER: { title: "ユーザー管理" },
  USER_DM: { title: "ユーザー管理(DM管理者)" },
  CORPORATION_USER_DM: { title: "法人ユーザー管理（DM管理者）" },
  CONTENTS_MANAGEMENT: { title: "コンテンツ管理" },
  CONTENTS_MANAGEMENT_ORIGINAL: { title: "コンテンツ管理（施設オリジナル）" },
  CREATE_CONTENTS: { title: "コンテンツ作成" },
  CREATE_CONTENTS_ORIGINAL: { title: "コンテンツ作成（施設オリジナル）" },
  EDIT_CONTENTS: { title: "コンテンツ編集" },
  EDIT_CONTENTS_ORIGINAL: { title: "コンテンツ編集（施設オリジナル）" },
  EDIT_WORK_CONTENTS: { title: "コンテンツ編集" },
  EDIT_WORK_CONTENTS_ORIGINAL: { title: "コンテンツ編集（施設オリジナル）" },
  MEDIA_UPLOAD: { title: "メディアアップロード" },
  MEDIA_UPLOAD_ORIGINAL: { title: "メディアアップロード（施設オリジナル）" },
  CUSTOM_LIST: { title: "レポート一覧" },
  CATEGORY_TEST: { title: "まとめテスト" },
  CATEGORY_TEST_RESULT: { title: "まとめテスト結果" },
  CATEGORY_TEST_HISTORY: { title: "まとめテスト結果履歴" },
  CATEGORY_TEST_HISTORY_RESULT: { title: "まとめテスト結果一覧" },
  LEGAL_CURRICULUM_MST_LIST: { title: "法定研修管理" },
  LEGAL_CURRICULUM_MST_LIST_DM: { title: "法定研修管理（DM管理者）" },
  LEGAL_CURRICULUM_MST_CREATE: { title: "法定研修カリキュラム作成" },
  LEGAL_CURRICULUM_MST_CREATE_DM: { title: "法定研修カリキュラム作成（DM管理者）" },
  LEGAL_CURRICULUM_MST: { title: "法定研修カリキュラム" },
  LEGAL_CURRICULUM_MST_DM: { title: "法定研修カリキュラム（DM管理者）" },
  LEGAL_CURRICULUM_LIST: { title: "法定研修カリキュラム" },
  CAREER_EXCLUSION_DM: { title: "キャリアパステーマ除外管理" },
  REPORT_PREVIEW: { title: "PDFプレビュー" },
  ANNOUNCE_MST_LIST: { title: "お知らせ管理" },
  ANNOUNCE_MST_CREATE: { title: "お知らせ管理" },
  ANNOUNCE_MST: { title: "お知らせ管理" },
  ANNOUNCE_LIST: { title: "お知らせ一覧" },
  ANNOUNCE: { title: "お知らせ" },
  USERDATA_POLICY: { title: "利用者情報の送信ポリシー" },
  USER_INFO: { title: "ユーザー情報" },
  USER_IMPORT_HISTORY: { title: "ユーザーインポート履歴" },
  USER_IMPORT_HISTORY_DM: { title: "ユーザーインポート履歴" },
  TUTORIAL: { title: "チュートリアル" },
  USER_NAME: { title: "ユーザ名" },
  STUDY_HISTORY: { title: "学習履歴" },
  CAREER_MST: { title: "キャリアパス管理" },
  PACKAGE: { title: "パッケージ" },
  PACKAGE_MANAGEMENT: { title: "パッケージ管理" },
  PACKAGE_CONTENTS: { title: "コンテンツ" },
  PACKAGE_CONTENTS_TEST: { title: "コンテンツテスト" },
  PACKAGE_CONTENTS_TEST_COMPLETED: { title: "コンテンツテスト結果" },
  GROUP_STUDY: { title: "集団受講" },
};

export const MESSAGE = {
  API_GENERAL_ERROR_TITLE: "エラーが発生しました",
  API_GENERAL_ERROR_BODY: "エラーが発生しました。大変申し訳ございませんが、しばらくたってからもう一度お試しください。",
  API_REGISTER_MESSAGE: "登録しました。",
  API_COPY_MESSAGE: "コピーしました。",
  API_RELEASE_MESSAGE: "公開しました。",
  API_CLOSE_MESSAGE: "非公開にしました。",
  API_DELETE_MESSAGE: "削除しました。",
  API_REMOVE_MESSAGE: "取り消しました。",
  API_UPDATE_MESSAGE: "更新しました。",
};

export const VALIDATIION_MESSAGE = {
  INVALID_PHONE_NUMBER: "電話番号の桁数が不正です。11桁の有効な電話番号を入力してください。",
  INVALID_EMAIL: "メールアドレスの形式が正しくありません",
  INVALID_USERID: "ユーザーIDに自動発行形式(アカウントID+数字)の値は利用できません。",
  INVALID_USERID_LAST_PERIOD: "ユーザーIDの最後に「.」は利用できません。",
  INVALID_USERID_CONTINUOUS_PERIOD: "ユーザーIDに「.」を連続で利用することはできません。",
};

export const TABLE_LIMIT = {
  default: 30,
  questionBoxAccount: 30,
  questionBoxDm: 10,
  mediaUpload: 30,
};
0;

export const ACCOUNT_ORIGINAL_GROUP_ID = 4;

export const AUTH_ERROR = {
  TOKEN_EXPIRED: "token expired",
  USER_INVALD: "user invalid",
  ACCOUNT_INVALID: "account invalid",
  ACCOUNT_OVER_END_DATE: "account over end date",
  FRONT_INVALID: "front invalid",
  ACCOUNT_NO_PERMISSION: "account no permission",
  LOGIN_INVALID: "login invalid",
  USER_TIMEOUT: "user timeout",
};

export const MYPAGE_TAB_INDEX_NORMAL_PLAN_WITH_ACCOUNT_ORIGINAL: { [id: string]: number } = {
  CAREER: 0,
  LEGAL: 1,
  FREE_CONTENTS: 2,
  ACCOUNT_ORIGINAL: 3,
  TUTORIAL: 4,
  BOOKMARK: 5,
  HISTORY: 6,
};
export const MYPAGE_TAB_INDEX_NORMAL_PLAN_WITHOUT_ACCOUNT_ORIGINAL: { [id: string]: number } = {
  CAREER: 0,
  LEGAL: 1,
  FREE_CONTENTS: 2,
  TUTORIAL: 3,
  BOOKMARK: 4,
  HISTORY: 5,
};
export const MYPAGE_TAB_INDEX_DM_SUPPORT_PLAN_WITH_ACCOUNT_ORIGINAL: { [id: string]: number } = {
  LEGAL: 0,
  FREE_CONTENTS: 1,
  ACCOUNT_ORIGINAL: 2,
  TUTORIAL: 3,
  BOOKMARK: 4,
  HISTORY: 5,
};
export const MYPAGE_TAB_INDEX_DM_SUPPORT_PLAN_WITHOUT_ACCOUNT_ORIGINAL: { [id: string]: number } = {
  LEGAL: 0,
  FREE_CONTENTS: 1,
  TUTORIAL: 2,
  BOOKMARK: 3,
  HISTORY: 4,
};
export const MYPAGE_TAB_NAME_NORMAL_PLAN_WITH_ACCOUNT_ORIGINAL: mypageTabName[] = [
  "CAREER",
  "LEGAL",
  "FREE_CONTENTS",
  "ACCOUNT_ORIGINAL",
  "TUTORIAL",
  "BOOKMARK",
  "HISTORY",
];
export const MYPAGE_TAB_NAME_NORMAL_PLAN_WITHOUT_ACCOUNT_ORIGINAL: mypageTabName[] = [
  "CAREER",
  "LEGAL",
  "FREE_CONTENTS",
  "TUTORIAL",
  "BOOKMARK",
  "HISTORY",
];
export const MYPAGE_TAB_NAME_DM_SUPPORT_PLAN_WITH_ACCOUNT_ORIGINAL: mypageTabName[] = [
  "LEGAL",
  "FREE_CONTENTS",
  "ACCOUNT_ORIGINAL",
  "TUTORIAL",
  "BOOKMARK",
  "HISTORY",
];
export const MYPAGE_TAB_NAME_DM_SUPPORT_PLAN_WITHOUT_ACCOUNT_ORIGINAL: mypageTabName[] = [
  "LEGAL",
  "FREE_CONTENTS",
  "TUTORIAL",
  "BOOKMARK",
  "HISTORY",
];

export const MYPAGE_TAB_LABEL_LIST: { [id: string]: string } = {
  CAREER: "キャリアパス",
  LEGAL: "法定研修",
  FREE_CONTENTS: "フリーコンテンツ",
  ACCOUNT_ORIGINAL: "施設オリジナル",
  TUTORIAL: "チュートリアル",
  BOOKMARK: "ブックマーク",
  HISTORY: "学習履歴",
};

export const MYPAGE_TAB_TRACKING_GA4_LIST: { [id: string]: { pagePath: string; pageTitle: string } } = {
  CAREER: { pagePath: "mypage/career", pageTitle: "マイページ | キャリアパス" },
  LEGAL: { pagePath: "mypage/legal", pageTitle: "マイページ | 法定研修" },
  PACKAGE: { pagePath: "mypage/package", pageTitle: "マイページ | パッケージ" },
  FREE_CONTENTS: { pagePath: "mypage/free", pageTitle: "マイページ | フリーコンテンツ" },
  ACCOUNT_ORIGINAL: { pagePath: "mypage/account-original", pageTitle: "マイページ | 施設オリジナル" },
  BOOKMARK: {
    pagePath: "mypage/bookmark",
    pageTitle: "マイページ | ブックマーク",
  },
  HISTORY: {
    pagePath: "mypage/history",
    pageTitle: "マイページ | 視聴履歴",
  },
  TUTORIAL: {
    pagePath: "mypage/tutorial",
    pageTitle: "マイページ | チュートリアル",
  },
};

export const questionBoxAccountUnsolvedTableHeaderSettings = [
  {
    name: "ステータス",
    key: "status",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "質問者",
    key: "questionUserName",
    click: true,
    align: "left",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "コンテンツ名",
    key: "contentsName",
    click: true,
    align: "left",
    width: 3,
    limit: 30,
  } as customTableHeaderType,
  {
    name: "最終質問日時",
    key: "lastQuestionedAt",
    click: true,
    align: "center",
    width: 3,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "最終回答者",
    key: "lastRepliedUser",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "最終コメント",
    key: "lastComment",
    click: true,
    align: "left",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
];

export const questionBoxAccountSolvedTableHeaderSettings = [
  {
    name: "質問者",
    key: "questionUserName",
    click: true,
    align: "left",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "コンテンツ名",
    key: "contentsName",
    click: true,
    align: "left",
    width: 3,
    limit: 30,
  } as customTableHeaderType,
  {
    name: "最終質問日時",
    key: "lastQuestionedAt",
    click: true,
    align: "center",
    width: 3,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "最終回答者",
    key: "lastRepliedUser",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "最終コメント",
    key: "lastComment",
    click: true,
    align: "left",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
];

export const questionBoxDmUnsolvedTableHeaderSettings = [
  {
    name: "ステータス",
    key: "status",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "施設名",
    key: "accountName",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "質問者",
    key: "questionUserName",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "コンテンツ名",
    key: "contentsName",
    click: true,
    align: "center",
    width: 2,
    limit: 30,
  } as customTableHeaderType,
  {
    name: "最終質問日時",
    key: "lastQuestionedAt",
    click: true,
    align: "center",
    width: 3,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "最終回答者",
    key: "lastRepliedUser",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "最終コメント",
    key: "lastComment",
    click: true,
    align: "left",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
];

export const questionBoxDmSolvedTableHeaderSettings = [
  {
    name: "施設名",
    key: "accountName",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "質問者",
    key: "questionUserName",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "コンテンツ名",
    key: "contentsName",
    click: true,
    align: "center",
    width: 2,
    limit: 30,
  } as customTableHeaderType,
  {
    name: "最終質問日時",
    key: "lastQuestionedAt",
    click: true,
    align: "center",
    width: 3,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "最終回答者",
    key: "lastRepliedUser",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "最終コメント",
    key: "lastComment",
    click: true,
    align: "left",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
];

const monthWidth = 2;
const monthLimit = 2;

const studyStatusLegalTableCommonHeader = [
  {
    name: "4月",
    key: "april",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "5月",
    key: "may",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "6月",
    key: "june",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "7月",
    key: "july",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "8月",
    key: "august",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "9月",
    key: "september",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "10月",
    key: "october",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "11月",
    key: "november",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "12月",
    key: "december",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "1月",
    key: "january",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "2月",
    key: "february",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "3月",
    key: "march",
    click: true,
    align: "center",
    width: monthWidth,
    limit: monthLimit,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "最終アクティブ",
    key: "latestOperationTime",
    click: true,
    align: "center",
    width: 5,
    limit: 7,
    sortable: true,
  } as customTableHeaderType,
];

export const studyStatusLegalTableHeaderSettings = [
  // { name: "ID", key: "userId", click: true, align: "center", width: 2, limit: 20 } as customTableHeaderType,
  {
    name: "名前",
    key: "name",
    click: true,
    align: "center",
    width: 12,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
].concat(studyStatusLegalTableCommonHeader);

export const studyStatusLegalTableWithStudyGroupNameHeaderSettings = [
  // { name: "ID", key: "userId", click: true, align: "center", width: 2, limit: 20 } as customTableHeaderType,
  {
    name: "名前",
    key: "name",
    click: true,
    align: "left",
    width: 7,
    limit: 10,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "受講グループ",
    key: "studyGroupName",
    click: true,
    align: "left",
    width: 5,
    limit: 10,
    sortable: true,
  } as customTableHeaderType,
].concat(studyStatusLegalTableCommonHeader);

export const studyStatusCareerTableHeaderSettings = [
  // { name: "ID", key: "userId", click: true, align: "center", width: 2, limit: 20 } as customTableHeaderType,
  {
    name: "名前",
    key: "name",
    click: true,
    align: "left",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "現在Lv",
    key: "level",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "受講済進捗",
    key: "progress",
    click: true,
    align: "center",
    width: 4,
    limit: 40,
    sortable: true,
  } as customTableHeaderType,

  {
    name: "最終アクティブ",
    key: "lastedLoggedin",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
];

export const studyStatusCareerWithStudyGroupTableHeaderSettings = [
  // { name: "ID", key: "userId", click: true, align: "center", width: 2, limit: 20 } as customTableHeaderType,
  {
    name: "名前",
    key: "name",
    click: true,
    align: "left",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "受講グループ",
    key: "studyGroupName",
    click: true,
    align: "left",
    width: 2,
    limit: 10,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "現在Lv",
    key: "level",
    click: true,
    align: "center",
    width: 1,
    limit: 10,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "受講済進捗",
    key: "progress",
    click: true,
    align: "center",
    width: 2,
    limit: 40,
    sortable: true,
  } as customTableHeaderType,

  {
    name: "最終アクティブ",
    key: "lastedLoggedin",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
];

export const studyStatusUserLegalTableHeaderSettings = [
  { name: "月", key: "month", click: true, align: "center", width: 1, limit: 3 } as customTableHeaderType,
  { name: "テーマ", key: "name", click: true, align: "right", width: 5, limit: 50 } as customTableHeaderType,
  {
    name: "ステータス",
    key: "themeStatus",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  { name: "受講日時", key: "studyDate", click: true, align: "center", width: 3, limit: 20 } as customTableHeaderType,
  {
    name: "レポート確認",
    key: "reportStatus",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "進捗",
    key: "contentsProgress",
    click: true,
    align: "center",
    width: 4,
    limit: 10,
  } as customTableHeaderType,
];

export const studyStatusUserCareerTableHeaderSettings = [
  { name: "テーマ", key: "name", click: true, align: "right", width: 8, limit: 50 } as customTableHeaderType,
  {
    name: "ステータス",
    key: "themeStatus",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  { name: "受講日時", key: "studyDate", click: true, align: "center", width: 3, limit: 20 } as customTableHeaderType,
  {
    name: "進捗",
    key: "contentsProgress",
    click: true,
    align: "center",
    width: 4,
    limit: 10,
  } as customTableHeaderType,
];

export const studyStatusUserFreeTableHeaderSettings = [
  { name: "テーマ", key: "name", click: true, align: "right", width: 8, limit: 50 } as customTableHeaderType,
  {
    name: "ステータス",
    key: "themeStatus",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  { name: "受講日時", key: "studyDate", click: true, align: "center", width: 3, limit: 20 } as customTableHeaderType,
  {
    name: "進捗",
    key: "contentsProgress",
    click: true,
    align: "center",
    width: 4,
    limit: 10,
  } as customTableHeaderType,
];

export const studyStatusUserAccountOriginalTableHeaderSettings = [
  { name: "テーマ", key: "name", click: true, align: "right", width: 8, limit: 50 } as customTableHeaderType,
  {
    name: "ステータス",
    key: "themeStatus",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  { name: "受講日時", key: "studyDate", click: true, align: "center", width: 3, limit: 20 } as customTableHeaderType,
  {
    name: "進捗",
    key: "contentsProgress",
    click: true,
    align: "center",
    width: 4,
    limit: 10,
  } as customTableHeaderType,
];

export const accountTableHeaderSettings = [
  {
    name: "施設ID",
    key: "id",
    click: true,
    align: "left",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "施設名",
    key: "name",
    click: true,
    align: "left",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "statusLabel",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "デモ",
    key: "demoFlgLabel",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "プラン",
    key: "accountPlanLabel",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "契約終了日",
    key: "usageEndDateText",
    click: true,
    align: "center",
    width: 1,
    limit: 30,
    sortable: true,
  } as customTableHeaderType,
  // {
  //   name: "パッケージ利用",
  //   key: "packageFlgLabel",
  //   click: true,
  //   align: "center",
  //   width: 1,
  //   limit: 20,
  //   sortable: true,
  // } as customTableHeaderType,
];

export const accountTableHeaderPackageItem = [
  {
    name: "パッケージ",
    key: "packageItems",
    click: true,
    align: "left",
    width: 2,
    limit: 1000,
    sortable: false,
  } as customTableHeaderType,
];

export const corporationAccountTableHeaderSettings = [
  {
    name: "法人ID",
    key: "id",
    click: true,
    align: "left",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "法人名",
    key: "name",
    click: true,
    align: "left",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "statusLabel",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
];

export const userTableHeaderSettings = [
  {
    name: "ユーザーID",
    key: "userId",
    click: true,
    align: "left",
    width: 2,
    limit: 30,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "名前",
    key: "name",
    click: true,
    align: "left",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "権限",
    key: "type",
    click: true,
    align: "left",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "現在レベル",
    key: "levelLabel",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "飛び級可能レベル",
    key: "skipLevelLabel",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "受講グループ",
    key: "studyGroupName",
    click: true,
    align: "left",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "statusLabel",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
];

export const userImportHistoryTableHeaderSettings = [
  { name: "インポート時刻", key: "updatedAt", click: false, align: "center", width: 3 } as customTableHeaderType,
  {
    name: "ファイル名",
    key: "fileName",
    click: false,
    align: "left",
    width: 3,
    limit: 50,
  } as customTableHeaderType,
  {
    name: "処理ステータス",
    key: "statusLabel",
    click: false,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "インポート件数",
    key: "importCount",
    click: false,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "結果ダウンロード",
    key: "downloadResult",
    click: false,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
];

export const userImportHistoryErrorMessageTableHeaderSettings = [
  { name: "行数", key: "rowNo", click: false, align: "center", width: 1 } as customTableHeaderType,
  {
    name: "メッセージ",
    key: "message",
    click: false,
    align: "left",
    width: 10,
    limit: 100,
  } as customTableHeaderType,
];

export const corporationUserTableHeaderSettings = [
  {
    name: "ユーザーID",
    key: "userId",
    click: true,
    align: "left",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "名前",
    key: "name",
    click: true,
    align: "left",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "statusLabel",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
];

export const userUploadTableHeaderSettings = [
  { name: "ユーザーID", key: "userId", click: true, align: "center", width: 2, limit: 20 } as customTableHeaderType,
  {
    name: "パスワード",
    key: "password",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "姓",
    key: "lastName",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "名",
    key: "firstName",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "権限",
    key: "type",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "飛び級可能レベル",
    key: "skipLevel",
    click: true,
    align: "center",
    width: 2,
    limit: 2,
  } as customTableHeaderType,
  {
    name: "受講グループ",
    key: "studyGroupName",
    click: true,
    align: "center",
    width: 2,
    limit: 2,
  } as customTableHeaderType,
];

export const contentsTableHeaderSettings = [
  {
    name: "サムネイル",
    key: "image",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "名前",
    key: "name",
    click: true,
    align: "center",
    width: 2,
    limit: 50,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "順序",
    key: "ord",
    click: true,
    align: "center",
    width: 1,
    limit: 5,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "statusLabel",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "公開/非公開",
    key: "releaseFlgLabel",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "新規タブ",
    key: "openNewTab",
    click: false,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
];

export const categoryTableHeaderSettings = [
  {
    name: "サムネイル",
    key: "image",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "名前",
    key: "name",
    click: true,
    align: "center",
    width: 2,
    limit: 50,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "順序",
    key: "ord",
    click: true,
    align: "center",
    width: 1,
    limit: 5,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "statusLabel",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "公開/非公開",
    key: "releaseFlgLabel",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
];

export const themeTableWithGenreTagHeaderSettings = [
  {
    name: "サムネイル",
    key: "image",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "名前",
    key: "name",
    click: true,
    align: "center",
    width: 5,
    limit: 50,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "ジャンルタグ",
    key: "categoryGenreName",
    click: true,
    align: "center",
    width: 3,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "キャリアパス設定",
    key: "connectCareers",
    click: true,
    align: "center",
    width: 1,
    limit: 100,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "順序",
    key: "ord",
    click: true,
    align: "center",
    width: 1,
    limit: 5,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "statusLabel",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "公開/非公開",
    key: "releaseFlgLabel",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
];

export const themeTableHeaderSettings = [
  {
    name: "サムネイル",
    key: "image",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "名前",
    key: "name",
    click: true,
    align: "center",
    width: 5,
    limit: 50,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "キャリアパス設定",
    key: "connectCareers",
    click: true,
    align: "center",
    width: 1,
    limit: 100,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "順序",
    key: "ord",
    click: true,
    align: "center",
    width: 1,
    limit: 5,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "statusLabel",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
  {
    name: "公開/非公開",
    key: "releaseFlgLabel",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
    sortable: true,
  } as customTableHeaderType,
];

export const mediaUploadHeaderSettings = [
  {
    name: "名称",
    key: "name",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "サムネイル",
    key: "thumbnail",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "再生時間",
    key: "playtime",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "変換ステータス",
    key: "status",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "設定済コンテンツ",
    key: "contentsName",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
];

export const studyStatusAccountOriginalHeaderSettings = [
  {
    name: "テーマ",
    key: "theme",
    click: true,
    align: "left",
    width: 2,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "コンテンツ",
    key: "contents",
    click: true,
    align: "center",
    width: 2,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "視聴済進捗",
    key: "progress",
    click: true,
    align: "center",
    width: 1,
    limit: 10,
  } as customTableHeaderType,
];

export const studyStatusPackageHeaderSettings = [
  {
    name: "パッケージ",
    key: "packageName",
    click: true,
    align: "left",
    width: 3,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "修了済進捗",
    key: "progress",
    click: true,
    align: "center",
    width: 3,
    limit: 10,
  } as customTableHeaderType,
];

export const studyStatusFreeContentsHeaderSettings = [
  {
    name: "テーマ",
    key: "theme",
    click: true,
    align: "left",
    width: 2,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "コンテンツ",
    key: "contents",
    click: true,
    align: "center",
    width: 2,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "視聴済進捗",
    key: "progress",
    click: true,
    align: "center",
    width: 1,
    limit: 10,
  } as customTableHeaderType,
];

export const studyStatusTutorialHeaderSettings = [
  {
    name: "テーマ",
    key: "theme",
    click: true,
    align: "left",
    width: 2,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "コンテンツ",
    key: "contents",
    click: true,
    align: "center",
    width: 2,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "視聴済進捗",
    key: "progress",
    click: true,
    align: "center",
    width: 1,
    limit: 10,
  } as customTableHeaderType,
];

export const studyStatusAccountOriginalContentsHeaderSettings = [
  {
    name: "名前",
    key: "name",
    click: true,
    align: "center",
    width: 2,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "status",
    click: true,
    align: "center",
    width: 1,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "視聴開始日時",
    key: "viewStartDatetime",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "視聴完了日時",
    key: "viewEndDatetime",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
];

export const studyStatusPackageDtlHeaderSettings = [
  {
    name: "名前",
    key: "name",
    click: true,
    align: "left",
    width: 2,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "status",
    click: true,
    align: "center",
    width: 1,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "受講開始日時",
    key: "viewStartDatetime",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "修了日時",
    key: "viewEndDatetime",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
];

export const studyStatusFreeContentsDtlHeaderSettings = [
  {
    name: "名前",
    key: "name",
    click: true,
    align: "left",
    width: 2,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "status",
    click: true,
    align: "center",
    width: 1,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "視聴開始日時",
    key: "viewStartDatetime",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "視聴完了日時",
    key: "viewEndDatetime",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
];

export const studyStatusTutorialDtlHeaderSettings = [
  {
    name: "名前",
    key: "name",
    click: true,
    align: "center",
    width: 2,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "status",
    click: true,
    align: "center",
    width: 1,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "視聴開始日時",
    key: "viewStartDatetime",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "視聴完了日時",
    key: "viewEndDatetime",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
];

export const studyStatusDtlTermHeaderSettings = [
  {
    name: "名前",
    key: "name",
    click: true,
    align: "center",
    width: 1,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "status",
    click: true,
    align: "center",
    width: 1,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "受講日時",
    key: "studiedTime",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "レポート",
    key: "report",
    click: true,
    align: "left",
    width: 3,
    limit: 1000,
  } as customTableHeaderType,
];

export const studyStatusDtlThemeHeaderSettings = [
  {
    name: "名前",
    key: "name",
    click: true,
    align: "center",
    width: 1,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "status",
    click: true,
    align: "center",
    width: 1,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "受講日時",
    key: "studiedTime",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "レポート",
    key: "report",
    click: true,
    align: "left",
    width: 3,
    limit: 1000,
  } as customTableHeaderType,
];

export const studyStatusDtlUserSettings = [
  {
    name: "月",
    key: "month",
    click: true,
    align: "center",
    width: 1,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "テーマ",
    key: "themeName",
    click: true,
    align: "center",
    width: 3,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "status",
    click: true,
    align: "center",
    width: 2,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "受講日時",
    key: "studiedTime",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "レポート",
    key: "report",
    click: true,
    align: "left",
    width: 7,
    limit: 1000,
  } as customTableHeaderType,
];

export const categoryTestHistoryTableHeaderSettings = [
  { name: "結果", key: "pass", click: true, align: "center", width: 1, limit: 20 } as customTableHeaderType,
  {
    name: "受験日",
    key: "testDate",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "正解率",
    key: "correctAnswerRate",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
];

export const careerExclusionTableHeaderSettings = [
  { name: "テーマ", key: "name", click: true, align: "center", width: 3, limit: 100 } as customTableHeaderType,
  {
    name: "合計時間",
    key: "totalPlaytimeString",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "受講対象外",
    key: "exclusionFlg",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
];

export const careerMstTableHeaderSettings = [
  { name: "テーマ", key: "themeName", click: true, align: "left", width: 3, limit: 100 } as customTableHeaderType,
  {
    name: "合計時間",
    key: "totalPlaytime",
    click: true,
    align: "center",
    width: 1,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "カテゴリー",
    key: "categoryName",
    click: true,
    align: "left",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
];

export const announceMstListTableHeaderSettings = [
  { name: "タイトル", key: "title", click: true, align: "left", width: 5, limit: 100 } as customTableHeaderType,
  {
    name: "公開開始日",
    key: "startDate",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "お知らせタイプ",
    key: "announceType",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
  {
    name: "対象",
    key: "target",
    click: true,
    align: "center",
    width: 2,
    limit: 10,
  } as customTableHeaderType,
  {
    name: "公開",
    key: "releaseFlgLabel",
    click: true,
    align: "center",
    width: 1,
    limit: 10,
  } as customTableHeaderType,
];

export const packageAccountTableHeaderSettings = [
  {
    name: "パッケージ",
    key: "packageName",
    click: true,
    align: "left",
    width: 3,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "説明",
    key: "description",
    click: true,
    align: "left",
    width: 3,
    limit: 100,
  } as customTableHeaderType,
  {
    name: "ステータス",
    key: "statusLabel",
    click: true,
    align: "center",
    width: 3,
    limit: 100,
  } as customTableHeaderType,

  {
    name: "対象",
    key: "settingFlg",
    click: true,
    align: "center",
    width: 2,
    limit: 20,
  } as customTableHeaderType,
];

export const dmAdminRoleDropdownOptions = [
  { key: "USER", text: "ユーザー", value: "USER" },
  { key: "ACCOUNT_ADMIN", text: "施設管理者", value: "ACCOUNT_ADMIN" },
  { key: "DM_ADMIN", text: "DM管理者", value: "DM_ADMIN" },
];

export const accountAdminRoleDropdownOptions = [
  { key: "USER", text: "ユーザー", value: "USER" },
  { key: "ACCOUNT_ADMIN", text: "施設管理者", value: "ACCOUNT_ADMIN" },
];

export const careerlevel = [
  { key: "Lv.1", text: "Lv.1", value: 1 },
  { key: "Lv.2", text: "Lv.2", value: 2 },
  { key: "Lv.3", text: "Lv.3", value: 3 },
  { key: "Lv.4", text: "Lv.4", value: 4 },
  { key: "Lv.5", text: "Lv.5", value: 5 },
];

export const announceTypeOptions = [
  { key: "BANNER_AND_ANNOUNCE", text: "バナー常駐型", value: "BANNER_AND_ANNOUNCE" },
  { key: "ANNOUNCE_ONLY", text: "通常お知らせ", value: "ANNOUNCE_ONLY" },
];

export const announceTargetOptions = [
  { key: "ALL", text: "すべて", value: "ALL" },
  { key: "ACCOUNT_ADMIN", text: "施設管理者のみ", value: "ACCOUNT_ADMIN" },
];

export const inquiryTypeOptions = [
  { value: "QUESTION", label: "質問" },
  { value: "PROBLEM", label: "不具合" },
  { value: "OTHER", label: "その他" },
];

export const inquiryDetailProblemOptions = [
  { value: "DEFECTS_IN_CONTENT", label: "コンテンツ内の不備について連絡したい" },
  { value: "DEFECTS_IN_OPERATION", label: "Dスタを操作する上で不具合があったので連絡したい" },
  { value: "OTHER", label: "その他" },
];

export const inquiryDetailProblemShortOptions = [
  { value: "DEFECTS_IN_CONTENT", label: "コンテンツ内不備" },
  { value: "DEFECTS_IN_OPERATION", label: "操作/不具合" },
  { value: "OTHER", label: "その他" },
];

export const inquiryDetailOtherOptions = [
  { value: "REQUEST_CONTENT", label: "追加してほしい受講コンテンツがある" },
  { value: "IMPROVEMENT_POINTS", label: "Dスタの操作や表示などで改善してほしいことがある" },
  { value: "UNKNOWN_POINT", label: "利用方法について不明な点がある" },
  { value: "OTHER", label: "その他" },
];

export const inquiryDetailOtherShortOptions = [
  { value: "REQUEST_CONTENT", label: "コンテンツ追加要望" },
  { value: "IMPROVEMENT_POINTS", label: "改善要望" },
  { value: "UNKNOWN_POINT", label: "利用方法" },
  { value: "OTHER", label: "その他" },
];

export const inquiryContentsDetailOptions = [
  { value: "CONTENTS", label: "コンテンツの内容" },
  { value: "TYPO", label: "動画や字幕の誤字脱字" },
  { value: "TEST", label: "テスト問題・選択肢の内容" },
  { value: "OTHER", label: "その他" },
];

export const inquiryContentsDetailShortOptions = [
  { value: "CONTENTS", label: "コンテンツ" },
  { value: "TYPO", label: "誤字脱字" },
  { value: "TEST", label: "テストの内容" },
  { value: "OTHER", label: "その他" },
];

export const inquiryRepliedStatusOptions = [
  { value: "TEMP_REPLIED", label: "一次返答済" },
  { value: "REPLIED", label: "返答済" },
];

export const inquiryDeviceOptions = [
  { value: "PC", label: "PC" },
  { value: "SP", label: "スマートフォン" },
  { value: "TABLET", label: "タブレット" },
  { value: "OTHER", label: "その他" },
];

export const inquiryBrowserOptions = [
  { value: "CHROME", label: "Chrome" },
  { value: "EDGE", label: "Edge" },
  { value: "SAFARI", label: "Safari" },
  { value: "OTHER", label: "その他" },
];

export const inquiryBoxDmSortKeyOptions = [
  { key: "ACCOUNT_ID", text: "施設ID", value: "ACCOUNT_ID" },
  { key: "TYPE", text: "分類", value: "TYPE" },
  { key: "DETAIL_TYPE", text: "問い合わせ内容", value: "DETAIL_TYPE" },
  { key: "ASSIGN_USER_ID", text: "担当者ID", value: "ASSIGN_USER_ID" },
  { key: "CONTENTS_ID", text: "コンテンツID", value: "CONTENTS_ID" },
  { key: "INQUIRY_USER_ID", text: "ユーザーID", value: "INQUIRY_USER_ID" },
  { key: "LAST_COMMENTED_AT", text: "最終更新日時", value: "LAST_COMMENTED_AT" },
];

export const inquiryBoxAccountSortKeyOptions = [
  { key: "TYPE", text: "分類", value: "TYPE" },
  { key: "DETAIL_TYPE", text: "問い合わせ内容", value: "DETAIL_TYPE" },
  { key: "INQUIRY_USER_ID", text: "ユーザー", value: "INQUIRY_USER_ID" },
  { key: "LAST_COMMENTED_AT", text: "最終更新日時", value: "LAST_COMMENTED_AT" },
];

export const accountPlanOptions: { key: accountPlanCode; text: string; value: string }[] = [
  { key: "FULL", text: "フル", value: "FULL" },
  { key: "LIGHT", text: "ライト", value: "LIGHT" },
  { key: "PACKAGE", text: "パッケージのみ", value: "PACKAGE" },
];

export const accountPlanAllowNotice = {
  // 法定研修進捗レポートの通知利用可否
  FULL: true,
  LIGHT: true,
  PACKAGE: false,
} as const;

export const packageFilterOptions = [
  { value: "NOT_SET", label: "未設定" },
  { value: "SET", label: "設定済" },
];

export const levelColorCodes = {
  level1Color: "#034bc4",
  level2Color: "#0241ab",
  level3Color: "#023893",
  level4Color: "#022e79",
  level5Color: "#012460",
  levelAverageColor: "#767676",
  levelMaxColor: "#f2711c",
  levelMinColor: "#2185d0",
};

export const levelColorCodeArray = [
  levelColorCodes.level1Color,
  levelColorCodes.level2Color,
  levelColorCodes.level3Color,
  levelColorCodes.level4Color,
  levelColorCodes.level5Color,
];

export const legalCategoryMenu = "c-1";

export const studyStatusColorCodes = {
  studied: "#4d9dd9",
  studying: "#00b5ad",
  notStudy: "#9e9e9e",
};

export const studyStatusColorCodeArray = [
  studyStatusColorCodes.studied,
  studyStatusColorCodes.studying,
  studyStatusColorCodes.notStudy,
];

export const monthKeyNumberMap = {
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12,
  january: 1,
  february: 2,
  march: 3,
};

export const monthKeyArray = [
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
  "january",
  "february",
  "march",
];

export const CLOUD_FRONT_COOKIES = {
  KEY_PAIR: "CloudFront-Key-Pair-Id",
  SIGNATURE: "CloudFront-Signature",
  POLICY: "CloudFront-Policy",
};

export const experienceYearText = {
  UNDER_1: "0~1年未満",
  FROM_1_TO_3: "1年~3年未満",
  FROM_3_TO_5: "3年~5年未満",
  OVER_6: "6年以上",
};

export const experienceYearOptions = [
  {
    key: "UNDER_1",
    text: experienceYearText.UNDER_1,
    value: "UNDER_1",
  },
  {
    key: "FROM_1_TO_3",
    text: experienceYearText.FROM_1_TO_3,
    value: "FROM_1_TO_3",
  },
  {
    key: "FROM_3_TO_5",
    text: experienceYearText.FROM_3_TO_5,
    value: "FROM_3_TO_5",
  },
  {
    key: "OVER_6",
    text: experienceYearText.OVER_6,
    value: "OVER_6",
  },
];

export const occupationOptions = ["介護", "看護", "ケアマネ_相談員", "事務", "その他"];

export const mailAddressRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
// SMS送信用の電話番号なので0始まりの11桁で制御
export const phoneNumberRegexp = /^[0]\d{10}$/;

export const QUESTION_SUPPORT_SYSTEM_USER = {
  ID: -1000,
  NAME: "サポート担当",
};

export const limitOptions = [
  {
    key: 25,
    text: 25,
    value: 25,
  },
  {
    key: 50,
    text: 50,
    value: 50,
  },
  {
    key: 100,
    text: 100,
    value: 100,
  },
  {
    key: 300,
    text: 300,
    value: 300,
  },
  {
    key: 500,
    text: 500,
    value: 500,
  },
];

export const subtitlelanguageOptions = [
  {
    key: "en",
    text: "英語",
    value: "en",
  },
  {
    key: "ja",
    text: "日本語",
    value: "ja",
  },
  {
    key: "ja_kana",
    text: "日本語かな",
    value: "ja_kana",
  },
];

export const personnelChangeTypeOptions = [
  {
    key: "REALTIME",
    text: "即時",
    value: "REALTIME",
  },
  {
    key: "SCHEDULE",
    text: "月指定",
    value: "SCHEDULE",
  },
];
