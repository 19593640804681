import React, { useEffect, useState } from "react";
import {
  testAnswerType,
  apiLoadTestItem,
  apiTestWorkItem,
  portalCareerTabType,
  portalCareerOrderType,
  accountOrderType,
  studyStatusType,
  announceType,
  announceTargetType,
  additionalInfoOccupationType,
  additionalInfoExperienceYearType,
  apiRegisterUser,
  apiUpdateUser,
  UserSignupRequest,
  AccountSignupRequest,
  apiRegisterUserInvite,
  apiRegisterContentsWork,
  apiReleaseContents,
  apiUpdateContents,
  apiRegisterPersonnelChange,
  flowType,
  careerType,
  apiRegisterInquiryResponse,
  apiLoadInquiryDm,
  apiUpdateInquiry,
  apiUpdateInquiryResponse,
  apiRegisterInquiryWorkComment,
  inquiryAccountSortKey,
  apiRegisterPackage,
  apiUpdatePackage,
  apiRegisterView,
  apiUpdateRestartView,
  apiUpdateCompleteView,
  apiRegisterTest,
  searchDemoType,
  searchAccountPlanType,
  searchPackageType,
  searchPackageFilterType,
  packageAccountBulkType,
  apiLoadAccountRequest,
  portalLegalOrderType,
  apiGroupStudyLoginRequest,
  apiLoadGroupStudyRequest,
  apiGroupStudyResponse,
  apiAccount,
  apiRegisterAccountRequest,
  apiUpdateAccountNotice,
} from "../types";
import {
  loadCategoryListApi,
  loadCareerListApi,
  loadThemeListApi,
  loadCareerThemeListApi,
  loadContentsListApi,
  loadContentsViewingApi,
  loadTestApi,
  loadTestedApi,
  loadMypageCareerApi,
  loadMypageLegalApi,
  loadMypageAccountOriginalApi,
  loadMypageBookmarkApi,
  loadContentsHistoryApi,
  loadMypageFreeContentsApi,
  loadMypageTutorialApi,
  loadStudyStatusUserApi,
  loadStudyStatusCareerUserApi,
  loadStudyStatusThemeApi,
  loadInquiryUserApi,
  loadInquiryDmApi,
  loadInquiryOneApi,
  loadInquiryOneDmApi,
  loadInquiryAccountApi,
  loadUserLoginAccountApi,
  loadUserApi,
  loadUserAccountApi,
  loadUserInfoApi,
  loadAccountApi,
  loadAccountOneApi,
  loadAccountAllApi,
  loadCorporationAccountApi,
  loadCorporationAccountAllApi,
  loadCategoryApi,
  loadCategoryValidStatusApi,
  loadThemeValidStatusApi,
  loadCategoryGroupApi,
  loadThemeApi,
  loadContentsMstListApi,
  loadContentsMstDtlApi,
  loadContentsWorkMstDtlApi,
  loadUserQuestionUnsolved,
  loadAccountUnconfirmedReportsApi,
  loadUserUnconfirmedReportsApi,
  loadContentsMediaApi,
  loadContentsMediaListApi,
  loadAccountPortalLegalApi,
  loadAccountPortalLegalDtlTermApi,
  loadAccountPortalLegalDtlThemeApi,
  loadAccountPortalLegalDtlUserApi,
  loadAccountPortalCareerApi,
  loadAccountPortalAccountOriginal,
  loadAccountPortalAccountOriginalContents,
  loadCustomContentsApi,
  loadCustomThemeApi,
  loadTestCategoryApi,
  loadTestResultCategoryApi,
  loadTestResultCategoryDtlApi,
  loadAccountPotalFreeContentsApi,
  loadAccountPortalFreeContentsDtlApi,
  loadAccountPotalTutorialApi,
  loadAccountPortalTutorialDtlApi,
  loadLegalCurriculumMstApi,
  loadLegalCurriculumMstListApi,
  loadLegalCurriculumListApi,
  loadStudyGroupApi,
  loadLegalCurriculumTemplateApi,
  loadCorporationPermitAccountsApi,
  loadLegalCurriculumYearApi,
  loadLegalCurriculumThemeApi,
  loadCareerExclusionApi,
  loadNextActionApi,
  loadAnnounceHomeApi,
  loadAnnounceDtlApi,
  loadAnnounceListApi,
  loadAnnounceMstDtlApi,
  loadAnnounceMstListApi,
  loadCategoryGenreApi,
  loadUserAdditionalInfoApi,
  loadInitialTutorialApi,
  loadInitialTutorialProgressApi,
  loadImportUserHistoryApi,
  loadCareerHistoryApi,
  loadCareerCertificateOfCompletionApi,
  loadCareerThemeApi,
  loadBreadcrumb,
  loadInviteInfoApi,
  loadCategoryAccountPortalApi,
  loadAccountInviteApi,
  loadAccountByCorporationAccountApi,
  loadStudyGroupByAccountIdApi,
  loadLegalCurriculumDocumentStatus,
  loadContentsBaseViewingApi,
  loadContentsApi,
  loadPackageAccountApi,
  loadPackageListApi,
  loadMypagePackageApi,
  loadMypagePackageDtlApi,
  loadPackageOneApi,
  loadAccountPortalPackageApi,
  loadAccountPortalPackageDtlApi,
  loadCorporationAccountForSearchApi,
  loadPackageForSearchApi,
  registerBookmarkApi,
  registerReportApi,
  registerTestApi,
  registerViewApi,
  registerInquiryApi,
  registerInquiryResponseDmApi,
  registerInquiryTempResponseDmApi,
  registerInquiryWorkCommentApi,
  registerInquiryResponseUserApi,
  registerInquiryReadedApi,
  registerFrontError,
  registerAccountApi,
  registerCorporationAccountApi,
  registerUserApi,
  registerCategoryApi,
  registerContentsWorkApi,
  registerThemeApi,
  registerTestTemplateWorkApi,
  registerContentsMediaApi,
  registerTestTemplateCategoryApi,
  registerTestCategoryApi,
  registerStudyGroupApi,
  registerLegalCurriculumApi,
  registerCareerExclusionApi,
  registerAnnounceApi,
  registerAnnounceReadedApi,
  registerAnnounceClosedApi,
  registerCategoryGenreApi,
  registerContentsEvaluationApi,
  registerMailAddressApi,
  registerUserAdditionalInfoApi,
  registerCareerApi,
  registerCareerThemeWorkApi,
  registerUserInvite,
  registerAccountInvite,
  registerReInvite,
  registerChangeLoginStartApi,
  registerLegalCurriculumStudiedForceApi,
  registerPersonnelChangeApi,
  registerPackageApi,
  registerPackageAccountApi,
  registerBulkPackageAccountApi,
  copyAnnounceApi,
  generateProfileUploadUrlApi,
  generateMediaUploadUrlApi,
  generateMediaDownloadUrlApi,
  generateThumbnailUploadUrlApi,
  generateAnnounceFileDownloadUrlApi,
  generateAnnounceFileUploadUrlApi,
  generateImportUserUploadUrlApi,
  generateImportUserDownloadUrlApi,
  generateSubtitlesUploadUrlApi,
  generateSubtitlesDownloadUrlApi,
  generateDocumentUploadUrlApi,
  generateDocumentDownloadUrlApi,
  generateLegalCurriculumDocumentApi,
  generateLegalCurriculumDocumentDownloadUrlApi,
  generateInquiryDownloadUrlApi,
  generateInquiryUploadUrlApi,
  importUserApi,
  uploadS3Api,
  releaseContentsApi,
  releaseContentsBulkApi,
  releaseAnnounceApi,
  releaseCareerThemeApi,
  releasePackageApi,
  closeContentsApi,
  closeContentsBulkApi,
  closePackageApi,
  closeAnnounceApi,
  rereleaseContentsApi,
  rereleasePackageApi,
  restartCategoryApi,
  restartThemeApi,
  restartContentsApi,
  updateReportApi,
  updateInquirySolvedApi,
  updateInquirySolvedDmApi,
  updateInquiryUnsolvedApi,
  updateInquiryUnsolvedDmApi,
  updateStopViewApi,
  updateRestartViewApi,
  updateUserApi,
  updateRestartUserApi,
  updateRestartAccountApi,
  updateCompleteViewApi,
  updateReportStatusConfirmdApi,
  updateInquiryResponseDmApi,
  updateInquiryResponseUserApi,
  updateAccountApi,
  updateCorporationAccountApi,
  updateRestartCorporationAccountApi,
  updateCategoryApi,
  updateCategoryFixedApi,
  updateThemeApi,
  updateThemeFixedApi,
  updateContentsApi,
  updateContentsWorkApi,
  updateTestTemplateApi,
  updateTestTemplateWorkApi,
  updateContentsMediaApi,
  updateStudyGroupApi,
  updateLegalCurriculumApi,
  updateCategoryGenreApi,
  updateAnnounceApi,
  updateUserAdditionalInfoApi,
  updateTutorialClosedApi,
  updateTutorialDisplayedApi,
  updateChangeLoginSuccessApi,
  updateInquiryApi,
  updatePackageApi,
  deleteBookmarkApi,
  deleteAccountApi,
  deleteCorporationAccountApi,
  deleteUserApi,
  deleteCategoryApi,
  deleteThemeApi,
  deleteContentsApi,
  deleteContentsWorkApi,
  deleteTestTemplateWorkApi,
  deleteContentsMediaApi,
  deleteStudyGroupApi,
  deleteLegalCurriculumApi,
  deleteCareerExclusionApi,
  deleteAnnounceApi,
  deleteCategoryGenreApi,
  deleteLegalCurriculumStudiedForceApi,
  deletePersonnelChangeApi,
  postRefreshTokenApi,
  loginSuccessApi,
  userSignUpApi,
  accountSignUpApi,
  deleteInvite,
  checkLoginKey,
  checkFrontVersionApi,
  updateReportStatusUnconfirmApi,
  startPackageApi,
  groupStudyLoginApi,
  loadGroupStudyApi,
  updateAccountNoticeApi,
} from "./repository";
import { useUserContext, setUser } from "../contexts/UserContext";
import { useAuthContext, authLogin, authLogout } from "../contexts/AuthContext";
import {
  useModalContext,
  isUserTimeout,
  isUserInvalid,
  isReloadUserCache,
  isUpdatedService,
} from "../contexts/ModalContext";
import { useBannerContext, hide, isError } from "../contexts/BannerContext";
import {
  getCookieToken,
  hasDiff,
  getCurrentAccountStorage,
  setMediaOriginalCookie,
  setLoginSessionErrorMessage,
} from "./../util/common.util";
import { MESSAGE, AUTH_ERROR } from "../static";
import { logout } from "../util/auth0.util";
import { errorMessageHandling, useErrorBannerHandling, useTokenApi, useTokenApiNoArgs } from "./api.hooks.base";
import { isBadRequestException } from "../util/exception/BadRequestException";

// ※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※
//  認証前(accessTokenなしで利用できるAPI)
// ※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※
export const useCheckFrontVersionApi = () => {
  const [isOldFrontVersion, setIsOldFrontState] = useState(false);
  const { dispatch: modalDispatch } = useModalContext();

  const callCheckFrontVersionApi = async () => {
    try {
      await checkFrontVersionApi();
    } catch (e) {
      if ((e as Error)?.message === AUTH_ERROR.FRONT_INVALID) {
        modalDispatch(isUpdatedService());
        setIsOldFrontState && setIsOldFrontState(true);
      } else {
        throw e;
      }
    }
  };

  return {
    isOldFrontVersion,
    callCheckFrontVersionApi,
  };
};

export const useLoadInviteInfoApi = () => {
  const [isLoading, setIsLoading] = useState(false);

  const callLoadInviteInfoApi = async (ulid: string) => {
    if (!ulid) {
      return {
        isSuccess: false,
        result: { inviteStatus: "DELETED" },
      };
    }
    try {
      setIsLoading(true);
      const result = await loadInviteInfoApi({ ulid });
      return {
        isSuccess: true,
        result: result,
      };
    } catch (e) {
      return {
        isSuccess: false,
        result: { inviteStatus: "DELETED" },
      };
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    callLoadInviteInfoApi,
  };
};

export const useUserSignUpApi = () => {
  const [isLoading, setIsLoading] = useState(false);

  const callUserSignUpApi = async (userSignup: UserSignupRequest) => {
    try {
      setIsLoading(true);
      const result = await userSignUpApi(userSignup);
      if (result.isSuccess) {
        return {
          isSuccess: true,
        };
      }
      return {
        isSuccess: false,
        errorType: result.errorType || "DEFAULT",
      };
    } catch (e) {
      return {
        isSuccess: false,
        errorType: "DEFAULT",
      };
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    callUserSignUpApi,
  };
};

export const useAccountSignUpApi = () => {
  const [isLoading, setIsLoading] = useState(false);

  const callAccountSignUpApi = async (accountSignUp: AccountSignupRequest) => {
    try {
      setIsLoading(true);
      const result = await accountSignUpApi(accountSignUp);
      if (result.isSuccess) {
        return {
          isSuccess: true,
          result: result,
        };
      }
      return {
        isSuccess: false,
        errorType: result.errorType || "DEFAULT",
      };
    } catch (e) {
      return {
        isSuccess: false,
        errorType: "DEFAULT",
      };
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    callAccountSignUpApi,
  };
};

export const useCheckLoginKeyApi = () => {
  const [isLoading, setIsLoading] = useState(false);

  const callCheckLoginKeyApi = async (loginType: "SMS" | "EMAIL", loginKey: string) => {
    try {
      setIsLoading(true);
      await checkLoginKey({ loginType, loginKey });
      return {
        isSuccess: true,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return {
        isSuccess: false,
        result: { errorMessage: e.message },
      };
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    callCheckLoginKeyApi,
  };
};

export const useLoginSuccessApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch: authDispatch } = useAuthContext();

  const callLoginSuccessApi = async (
    accessToken: string,
    expiresIn: number,
    flowType: flowType,
    currentPath?: string
  ) => {
    try {
      setIsLoading(true);
      const response = await loginSuccessApi(accessToken, flowType);
      // resposeにerrorが設定されている場合はエラーとして扱う
      if ("error" in response) {
        // サーバー側から422Errorが返却された場合はログイン失敗として扱う
        if (response.status === 422) {
          // sessionStorageにカスタムError情報を追加
          setLoginSessionErrorMessage(response.message);
        }
        throw new Error();
      }
      authDispatch(
        authLogin({
          accessToken: accessToken,
          expiresIn: expiresIn,
        })
      );
      return {
        isSuccess: true,
      };
    } catch (e) {
      // Dスタ側のログイン処理でしっぱいしたらauth0側のログイン状況も破棄する
      logout({
        userId: "NOT_SET",
        accountId: "NOT_SET",
        callFrom: "callLoginSuccessApi error",
        currentPath: flowType === "CHANGE_LOGIN" ? undefined : currentPath,
      });
      return {
        isSuccess: false,
      };
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    callLoginSuccessApi,
  };
};

export const useGroupStudyLoginApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const { dispatch: authDispatch } = useAuthContext();

  const callGroupStudyLoginApi = async (request: apiGroupStudyLoginRequest) => {
    try {
      setIsLoading(true);
      const response = await groupStudyLoginApi(request);

      return {
        isSuccess: true,
        result: response,
      };
    } catch (e) {
      return {
        isSuccess: false,
        result: { errorMessage: "ログインに失敗しました" },
      };
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    callGroupStudyLoginApi,
  };
};

export const useLoadGrupStudyApi = () => {
  const [isLoading, setIsLoading] = useState(false);

  const callLoadGroupStudyApi = async (
    request: apiLoadGroupStudyRequest
  ): Promise<
    { isSuccess: true; result: apiGroupStudyResponse } | { isSuccess: false; result: { errorMessage: string } }
  > => {
    try {
      setIsLoading(true);
      const response = await loadGroupStudyApi(request);
      return {
        isSuccess: true,
        result: response,
      };
    } catch {
      return {
        isSuccess: false,
        result: { errorMessage: "グループスタディ情報の取得に失敗しました" },
      };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    callLoadGroupStudyApi,
  };
};

// ※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※
// ユーザー情報の更新 ※固有制御が多いため個別実装
// ※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※
export const useLoadUserInfoApi = (shouldInvokeImmediately = false) => {
  const [isLoading, setIsLoading] = useState(false);
  const { contextData: auth, dispatch: authDispatch } = useAuthContext();
  const { contextData: user, dispatch: userDispatch } = useUserContext();
  const { dispatch: modalDispatch } = useModalContext();

  const forceLogout = () => {
    // loadUserInfoはcontextがログインだと画面ロード時に再度呼ばれるのでこのタイミングでcontext更新
    if (!auth.isInitialized || !auth.isLoggedIn) {
      modalDispatch(isUserInvalid());
      return;
    }
    // ログイン状態でない場合は強制ログアウトさせる
    authDispatch(authLogout(false));
    logout({
      userId: "NOT_SET",
      accountId: "NOT_SET",
      callFrom: "useLoadUserInfoApi",
      loginType: user?.loginType,
    });
  };

  const callLoadUserInfoApi = async (checkDiff = false) => {
    if (auth.isLoggedIn) {
      try {
        !checkDiff && setIsLoading(true);
        if (!getCookieToken()) {
          try {
            await postRefreshTokenApi();
          } catch (e) {
            // トークン再取得が失敗したときはapi側で期限切れしたととみなす
            modalDispatch(isUserTimeout());
            return {
              isSuccess: false,
            };
          }
        }
        let result = await loadUserInfoApi();
        if ("error" in result) {
          try {
            await postRefreshTokenApi();
            result = await loadUserInfoApi();
            if ("error" in result) {
              modalDispatch(isUserInvalid());
              return {
                isSuccess: false,
              };
            }
          } catch (e) {
            modalDispatch(isUserInvalid());
            return {
              isSuccess: false,
            };
          }
        }

        setMediaOriginalCookie(result.mediaOriginalCFCookies);
        if (checkDiff) {
          if (
            hasDiff(user, { ...result, token: user.token, currentAccount: user.currentAccount }, [
              "level",
              "mediaOriginalCFCookies",
              "existUserInfo",
              "existMailAddress",
              "tutorialFinished",
              "tutorialDisplayed",
              "tutorialClosed",
              "joinedCompanyDate",
              "birthDate",
            ])
          ) {
            result.status === "DELETED" ? modalDispatch(isUserInvalid()) : modalDispatch(isReloadUserCache());
          }
          if (user?.level !== result?.level) {
            // ユーザーのレベルが昇格により変わった場合は内容を更新する
            userDispatch(setUser({ ...result, token: user.token, currentAccount: user.currentAccount }));
            return;
          }
          if (
            user?.tutorialFinished !== result?.tutorialFinished ||
            user?.tutorialDisplayed !== result?.tutorialDisplayed ||
            user?.tutorialClosed !== result?.tutorialClosed
          ) {
            userDispatch(setUser({ ...result, token: user.token, currentAccount: user.currentAccount }));
          }
        } else {
          if (result.type === "CORPORATION_ADMIN") {
            const currentAccount = getCurrentAccountStorage();
            userDispatch(
              setUser(
                currentAccount
                  ? { ...result, token: user.token, currentAccount }
                  : { ...result, token: user.token, currentAccount: user.currentAccount }
              )
            );
          } else {
            userDispatch(setUser({ ...result, token: user.token, currentAccount: undefined }));
          }
        }
        return {
          isSuccess: true,
          result: result,
        };
      } catch (e) {
        forceLogout();
        return {
          isSuccess: false,
        };
      } finally {
        !checkDiff && setIsLoading(false);
      }
    } else {
      // 意図せず、ログイントークンが消えているときは強制ログアウト
      forceLogout();
    }
  };

  useEffect(() => {
    if (shouldInvokeImmediately) callLoadUserInfoApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    callLoadUserInfoApi,
  };
};
// ※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※
//  認証後(accessToken必須のAPI)
// ※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※

export const useLoadCorporationPermitAccountsApi = () => {
  // 実装補足:引数なしのapiかつ、エラー発生時、{isSuccess:false}を返すだけならapi定義だけで十分
  const { isExecuting: isLoading, callApi: callLoadCorporationPermitAccountsApi } = useTokenApiNoArgs({
    api: loadCorporationPermitAccountsApi,
  });
  return {
    isLoading,
    callLoadCorporationPermitAccountsApi,
  };
};

export const useLoadCategroyListApi = () => {
  // 実装補足：エラー発生時、返り値を加工する場合（apiエラーメッセージを画面に渡す）などはcustomErrorHandlingを実装する
  // errorMessageHandlingを使うと、result: { errorMessage: e.message },の形式で返却される
  // customErrorHandlingは共通エラー（front更新、トークン無効など）でない場合だけ呼ばれるので共通のapiチェックは意識不要
  const { isExecuting: isLoading, callApi: callLoadCategroyListApi } = useTokenApiNoArgs({
    api: loadCategoryListApi,
    customErrorHandling: errorMessageHandling,
  });
  return {
    isLoading,
    callLoadCategroyListApi,
  };
};

export const useLoadCareerListApi = () => {
  const { isExecuting: isLoading, callApi: callLoadCareerListApi } = useTokenApiNoArgs({
    api: loadCareerListApi,
    customErrorHandling: errorMessageHandling,
  });

  return {
    isLoading,
    callLoadCareerListApi,
  };
};

export const useLoadThemeListApi = () => {
  // 実装補足： 引数ありのapiの場合、useTokenApiを利用する
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadThemeListApi,
    customErrorHandling: errorMessageHandling,
  });

  // 実装補足：既存の引数形式（複数パラメータを渡す場合）を担保するために上書き
  const callLoadThemeListApi = async (accountOriginalFlg: boolean, categoryId: number) => {
    return await callApi({ accountOriginalFlg, categoryId });
  };

  return {
    isLoading,
    callLoadThemeListApi: callLoadThemeListApi,
  };
};

export const useLoadCareerThemeListApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadCareerThemeListApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadCareerThemeListApi = async (careerType: careerType | string, level: number) => {
    return await callApi({ careerType: careerType as careerType, level });
  };

  return {
    isLoading,
    callLoadCareerThemeListApi,
  };
};

export const useLoadContentsListApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadContentsListApi,
    customErrorHandling: (e) => {
      return {
        isSuccess: false,
        result: { errorMessage: e.message },
      };
    },
  });

  const callLoadContentsListApi = async (
    accountOriginalFlg: boolean,
    themeId: number,
    baseYear?: number,
    baseMonth?: number
  ) => {
    return await callApi({ accountOriginalFlg, themeId, baseYear, baseMonth });
  };
  return {
    isLoading,
    callLoadContentsListApi,
  };
};

export const useLoadContentsViewingApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadContentsViewingApi,
    customErrorHandling: (e) => {
      return {
        isSuccess: false,
        result: { errorMessage: e.message },
      };
    },
  });

  const callLoadContentsViewingApi = async (
    accountOriginalFlg: boolean,
    contentsId: number,
    baseYear: number,
    baseMonth: number,
    packageFlg: boolean,
    packageId: number
  ) => {
    return await callApi({ accountOriginalFlg, contentsId, baseYear, baseMonth, packageFlg, packageId });
  };
  return {
    isLoading,
    callLoadContentsViewingApi,
  };
};

export const useLoadTestApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadTestApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadTestApi = async (accountOriginalFlg: boolean, contentsId: number) => {
    return await callApi({ accountOriginalFlg, contentsId });
  };
  return {
    isLoading,
    callLoadTestApi,
  };
};

export const useLoadTestedApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadTestedApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadTestedApi = async (accountOriginalFlg: boolean, contentsId: number) => {
    return await callApi({ accountOriginalFlg, contentsId });
  };
  return {
    isLoading,
    callLoadTestedApi,
  };
};

export const useLoadMypageCareerApi = () => {
  const { isExecuting: isLoading, callApi: callLoadMypageCareerApi } = useTokenApiNoArgs({
    api: loadMypageCareerApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  return {
    isLoading,
    callLoadMypageCareerApi,
  };
};

export const useLoadMypageLegalApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadMypageLegalApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadMypageLegalApi = async (baseYear: number, baseMonth: number) => {
    return await callApi({ baseYear, baseMonth });
  };
  return {
    isLoading,
    callLoadMypageLegalApi,
  };
};

export const useLoadMypageAccountOriginalApi = () => {
  const { isExecuting: isLoading, callApi: callLoadMypageAccountOriginalApi } = useTokenApiNoArgs({
    api: loadMypageAccountOriginalApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  return {
    isLoading,
    callLoadMypageAccountOriginalApi,
  };
};

export const useLoadMypageBookmarkApi = () => {
  const { isExecuting: isLoading, callApi: callLoadMypageBookmarkApi } = useTokenApiNoArgs({
    api: loadMypageBookmarkApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isLoading,
    callLoadMypageBookmarkApi,
  };
};

export const useLoadContentsHistoryApi = () => {
  const { isExecuting: isLoading, callApi: callLoadContentsHistoryApi } = useTokenApiNoArgs({
    api: loadContentsHistoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isLoading,
    callLoadContentsHistoryApi,
  };
};

export const useLoadMypageFreeContentsApi = () => {
  const { isExecuting: isLoading, callApi: callLoadMypageFreeContentsApi } = useTokenApiNoArgs({
    api: loadMypageFreeContentsApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isLoading,
    callLoadMypageFreeContentsApi,
  };
};

export const useLoadMypageTutorialApi = () => {
  const { isExecuting: isLoading, callApi: callLoadMypageTutorialApi } = useTokenApiNoArgs({
    api: loadMypageTutorialApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isLoading,
    callLoadMypageTutorialApi,
  };
};

export const useLoadCustomContents = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadCustomContentsApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadCustomContents = async (contents: { accountOriginalFlg: boolean; id: number }[]) => {
    return await callApi({ contents });
  };
  return {
    isLoading,
    callLoadCustomContents,
  };
};

export const useLoadCustomTheme = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadCustomThemeApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadCustomTheme = async (themes: { accountOriginalFlg: boolean; id: number }[]) => {
    return await callApi({ themes });
  };
  return {
    isLoading,
    callLoadCustomTheme,
  };
};

export const useLoadAccountPortalLegalApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPortalLegalApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountPortalLegalApi = async (props: {
    accountId: string;
    baseYear: number;
    baseMonth: number;
    studyGroupId: number;
    orderType: portalLegalOrderType;
    desc: boolean;
    page: number;
    limit: number;
  }) => {
    return await callApi(props);
  };
  return {
    isLoading,
    callLoadAccountPortalLegalApi,
  };
};

export const useLoadAccountPortalLegalDtlTermApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPortalLegalDtlTermApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountPortalLegaDtlTermApi = async (
    baseYear: number,
    studyGroupType: "ALL" | "SELECT",
    studyGroupId: number,
    fromMonthOrd: number,
    toMonthOrd: number,
    studyStatuses: studyStatusType[],
    accountId: string
  ) => {
    return await callApi({
      baseYear,
      studyGroupType,
      studyGroupId,
      fromMonthOrd,
      toMonthOrd,
      studyStatuses,
      accountId,
    });
  };
  return {
    isLoading,
    callLoadAccountPortalLegaDtlTermApi,
  };
};

export const useLoadAccountPortalLegalDtlThemeApi = (withoutBannerAction?: boolean) => {
  const { dispatch: bannerDispatch } = useBannerContext();
  const { isExecuting: isLoading, callApi } = useTokenApi({
    beforeApiFunc: () => {
      !withoutBannerAction && bannerDispatch(hide());
    },
    api: loadAccountPortalLegalDtlThemeApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountPortalLegaDtlThemeApi = async (
    baseYear: number,
    studyGroupType: "ALL" | "SELECT",
    studyGroupId: number,
    themeId: number,
    studyStatuses: studyStatusType[],
    accountId: string
  ) => {
    return await callApi({
      baseYear,
      studyGroupType,
      studyGroupId,
      themeId,
      studyStatuses,
      accountId,
    });
  };
  return {
    isLoading,
    callLoadAccountPortalLegaDtlThemeApi,
  };
};

export const useLoadAccountPortalLegalDtlUserApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPortalLegalDtlUserApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountPortalLegaDtlUserApi = async (
    baseYear: number,
    studyGroupId: number,
    userId: number,
    studyStatuses: studyStatusType[],
    accountId: string
  ) => {
    return await callApi({
      baseYear,
      studyGroupId,
      userId,
      studyStatuses,
      accountId,
    });
  };
  return {
    isLoading,
    callLoadAccountPortalLegaDtlUserApi,
  };
};

export const useLoadAccountPortalCareerApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPortalCareerApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountPortalCareerApi = async (
    tab: portalCareerTabType | string,
    orderType: portalCareerOrderType | string,
    desc: boolean,
    page: number,
    limit: number,
    accountId: string
  ) => {
    return await callApi({
      tab: tab as portalCareerTabType,
      orderType: orderType as portalCareerOrderType,
      desc,
      page,
      limit,
      accountId,
    });
  };
  return {
    isLoading,
    callLoadAccountPortalCareerApi,
  };
};

export const useLoadAccountPortalFreeContentsApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPotalFreeContentsApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountPortalFreeContentsApi = async (accountId?: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadAccountPortalFreeContentsApi,
  };
};

export const useLoadAccountPortalFreeContentsDtlApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPortalFreeContentsDtlApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountPortalFreeContentsDtlApi = async (contentsId: number, accountId?: string) => {
    return await callApi({ contentsId, accountId });
  };
  return {
    isLoading,
    callLoadAccountPortalFreeContentsDtlApi,
  };
};

export const useLoadAccountPortalTutorialApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPotalTutorialApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountPortalTutorialApi = async (accountId?: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadAccountPortalTutorialApi,
  };
};

export const useLoadAccountPortalTutorialDtlApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPortalTutorialDtlApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountPortalTutorialDtlApi = async (contentsId: number, accountId?: string) => {
    return await callApi({ contentsId, accountId });
  };
  return {
    isLoading,
    callLoadAccountPortalTutorialDtlApi,
  };
};

export const useLoadAccountPortalAccountOriginalApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPortalAccountOriginal,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountPortalAccountOriginalApi = async (accountId?: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadAccountPortalAccountOriginalApi,
  };
};

export const useLoadAccountPortalAccountOriginalContentsApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPortalAccountOriginalContents,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountPortalAccountOriginalContentsApi = async (contentsId: number, accountId?: string) => {
    return await callApi({ contentsId, accountId });
  };
  return {
    isLoading,
    callLoadAccountPortalAccountOriginalContentsApi,
  };
};

export const useLoadStudyStatusUserApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadStudyStatusUserApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadStudyStatusUserApi = async (
    userId: number,
    accountOriginalFlg: boolean,
    categoryId: number,
    baseYear: number,
    careerType: careerType | undefined
  ) => {
    return await callApi({ userId, accountOriginalFlg, categoryId, baseYear, careerType: careerType as careerType });
  };
  return {
    isLoading,
    callLoadStudyStatusUserApi,
  };
};

export const useLoadStudyStatusCareerUserApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadStudyStatusCareerUserApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadStudyStatusCareerUserApi = async (
    userId: number,
    careerType: careerType,
    level: number,
    baseYear: number
  ) => {
    return await callApi({ userId, careerType, level, baseYear });
  };
  return {
    isLoading,
    callLoadStudyStatusCareerUserApi,
  };
};

export const useLoadStudyStatusThemeApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadStudyStatusThemeApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadStudyStatusThemeApi = async (userId: number, accountOriginalFlg: boolean, themeId: number) => {
    return await callApi({ userId, accountOriginalFlg, themeId });
  };
  return {
    isLoading,
    callLoadStudyStatusThemeApi,
  };
};

export const useLoadInquiryDmApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadInquiryDmApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadInquiryDmApi = async (params: apiLoadInquiryDm) => {
    return await callApi(params);
  };
  return {
    isLoading,
    callLoadInquiryDmApi,
  };
};

export const useLoadInquiryOneApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadInquiryOneApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadInquiryOneApi = async (inquiryId: number) => {
    return await callApi({ inquiryId });
  };
  return {
    isLoading,
    callLoadInquiryOneApi,
  };
};

export const useLoadInquiryOneDmApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadInquiryOneDmApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadInquiryOneDmApi = async (inquiryId: number) => {
    return await callApi({ inquiryId });
  };
  return {
    isLoading,
    callLoadInquiryOneDmApi,
  };
};

export const useLoadInquiryAccountApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadInquiryAccountApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadInquiryAccountApi = async (params: {
    solved: boolean;
    page: number;
    limit: number;
    sortKey: inquiryAccountSortKey;
    desc: boolean;
  }) => {
    return await callApi(params);
  };
  return {
    isLoading,
    callLoadInquiryAccountApi,
  };
};

export const useLoadInquiryUserApi = () => {
  const { isExecuting: isLoading, callApi: callLoadInquiryUserApi } = useTokenApiNoArgs({
    api: loadInquiryUserApi,
    customErrorHandling: errorMessageHandling,
  });
  return {
    isLoading,
    callLoadInquiryUserApi,
  };
};

export const useLoadUserLoginAccountApi = () => {
  const { isExecuting: isLoading, callApi: callLoadUserLoginAccountApi } = useTokenApiNoArgs({
    api: loadUserLoginAccountApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isLoading,
    callLoadUserLoginAccountApi,
  };
};

export const useLoadUserApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadUserApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadUserApi = async (
    params: {
      name: string | undefined;
      validOnly: boolean | undefined;
      orderType: accountOrderType | string | undefined;
      desc: boolean | undefined;
      page: number;
      limit: number | undefined;
    },
    accountId?: string
  ) => {
    return await callApi({
      params: {
        name: params.name as string,
        validOnly: params.validOnly as boolean,
        orderType: params.orderType as accountOrderType,
        desc: params.desc as boolean,
        page: params.page,
        limit: params.limit as number,
      },
      accountId,
    });
  };
  return {
    isLoading,
    callLoadUserApi,
  };
};

export const useLoadUserAccountApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadUserAccountApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadUserAccountApi = async (accountId: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadUserAccountApi,
  };
};

export const useLoadCategoryApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadCategoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadCategoryApi = async (accountOriginalFlg: boolean, accountId?: string) => {
    return await callApi({ accountOriginalFlg, accountId });
  };
  return {
    isLoading,
    callLoadCategoryApi,
  };
};

export const useLoadCategoryGroupApi = () => {
  const { isExecuting: isLoading, callApi: callLoadCategoryGroupApi } = useTokenApiNoArgs({
    api: loadCategoryGroupApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isLoading,
    callLoadCategoryGroupApi,
  };
};

export const useLoadThemeApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadThemeApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadThemeApi = async (accountOriginalFlg: boolean, accountId?: string) => {
    return await callApi({ accountOriginalFlg, accountId });
  };
  return {
    isLoading,
    callLoadThemeApi,
  };
};

export const useLoadContentsMstListApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadContentsMstListApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadContentsMstListApi = async (accountOriginalFlg: boolean, accountId?: string) => {
    return await callApi({ accountOriginalFlg, accountId });
  };
  return {
    isLoading,
    callLoadContentsMstListApi,
  };
};

export const useLoadContentsMstDtlApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadContentsMstDtlApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadContentsMstDtlApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    contentsId: number
  ) => {
    return await callApi({ accountOriginalFlg, accountId, contentsId });
  };
  return {
    isLoading,
    callLoadContentsMstDtlApi,
  };
};

export const useLoadContentsWorkMstDtlApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadContentsWorkMstDtlApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadContentsWorkMstDtlApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    contentsWorkId: number
  ) => {
    return await callApi({ accountOriginalFlg, accountId, contentsWorkId });
  };
  return {
    isLoading,
    callLoadContentsWorkMstDtlApi,
  };
};

export const useLoadCategoryAccountPortalApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadCategoryAccountPortalApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadCategoryAccountPortalApi = async (
    accountId: string | undefined,
    userId: number | undefined,
    baseYear: number
  ) => {
    return await callApi({ accountId, userId, baseYear });
  };
  return {
    isLoading,
    callLoadCategoryAccountPortalApi,
  };
};

export const useLoadCategoryValidStatusApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadCategoryValidStatusApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadCategoryValidStatusApi = async (accountOriginalFlg: boolean, accountId?: string) => {
    return await callApi({ accountOriginalFlg, accountId });
  };
  return {
    isLoading,
    callLoadCategoryValidStatusApi,
  };
};

export const useLoadThemeValidStatusApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadThemeValidStatusApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadThemeValidStatusApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    categoryId: number
  ) => {
    return await callApi({ accountOriginalFlg, accountId, categoryId });
  };
  return {
    isLoading,
    callLoadThemeValidStatusApi,
  };
};

export const useLoadAccountApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callLoadAccountApi = async (params: apiLoadAccountRequest) => {
    return await callApi(params);
  };
  return {
    isLoading,
    callLoadAccountApi,
  };
};

export const useLoadAccountOneApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountOneApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAccountOneApi = async (accountId?: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadAccountOneApi,
  };
};

export const useLoadAccountAllApi = () => {
  const { isExecuting: isLoading, callApi: callLoadAccountAllApi } = useTokenApiNoArgs({
    api: loadAccountAllApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isLoading,
    callLoadAccountAllApi,
  };
};

export const useLoadCorporationAccountApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadCorporationAccountApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadCorporationAccountApi = async (params: {
    name?: string;
    validOnly?: boolean;
    orderType?: accountOrderType;
    desc?: boolean;
    page: number;
    limit?: number;
  }) => {
    return await callApi(params);
  };
  return {
    isLoading,
    callLoadCorporationAccountApi,
  };
};

export const useLoadCorporationAccountAllApi = () => {
  const { isExecuting: isLoading, callApi: callLoadCorporationAccountAllApi } = useTokenApiNoArgs({
    api: loadCorporationAccountAllApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isLoading,
    callLoadCorporationAccountAllApi,
  };
};

export const useLoadContentsMediaListApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadContentsMediaListApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadContentsMediaListApi = async (
    name: string,
    notSet: boolean,
    page: number,
    limit: number,
    accountOriginalFlg: boolean,
    accountId?: string
  ) => {
    return await callApi({ name, notSet, page, limit, accountOriginalFlg, accountId });
  };
  return {
    isLoading,
    callLoadContentsMediaListApi,
  };
};

export const useLoadContentsMediaApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadContentsMediaApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadContentsMediaApi = async (accountOriginalFlg: boolean, accountId?: string) => {
    return await callApi({ accountOriginalFlg, accountId });
  };

  return {
    isLoading,
    callLoadContentsMediaApi,
  };
};

export const useLoadUserQuestionUnsolved = () => {
  const { callApi: callLoadUserQuestionUnsolved } = useTokenApiNoArgs({
    api: loadUserQuestionUnsolved,
    // 実装補足
    // メニュー上で非同期で読み込むため、不要な描画が走らないようbannerを使わない
    // 合わせて、useStateも利用しない
    noChangeState: true,
  });
  return {
    callLoadUserQuestionUnsolved,
  };
};

export const useLoadAccountUnconfirmedReports = () => {
  const { callApi } = useTokenApi({
    api: loadAccountUnconfirmedReportsApi,
    // 実装補足
    // メニュー上で非同期で読み込むため、不要な描画が走らないようbannerを使わない
    // 合わせて、useStateも利用しない
    noChangeState: true,
  });

  // メニュー上で非同期で読み込むため、不要な描画が走らないようbannerを使わない
  // 合わせて、useStateも利用しない
  const callLoadAccountUnconfirmedReports = async (accountId?: string) => {
    if (!accountId) {
      return {
        isSuccess: false,
        result: undefined,
      };
    }
    return await callApi({ accountId });
  };
  return {
    callLoadAccountUnconfirmedReports,
  };
};

export const useLoadTestCategoryApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadTestCategoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callLoadTestCategory = async (testTemplateCategoryId: number) => {
    return await callApi({ testTemplateCategoryId });
  };
  return {
    isLoading,
    callLoadTestCategory,
  };
};

export const useLoadTestResultCategoryApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadTestResultCategoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadTestResultCategory = async (userId: number | string | undefined) => {
    return await callApi({ userId });
  };
  return {
    isLoading,
    callLoadTestResultCategory,
  };
};

export const useLoadTestResultCategoryDtlApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadTestResultCategoryDtlApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadTestResultCategoryDtl = async (testResultCategoryId: number) => {
    return await callApi({ testResultCategoryId });
  };
  return {
    isLoading,
    callLoadTestResultCategoryDtl,
  };
};

export const useLoadUserUnconfirmedReports = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadUserUnconfirmedReportsApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadUsernconfirmedReports = async (userId: number, accountId: string) => {
    return await callApi({ userId, accountId });
  };
  return {
    isLoading,
    callLoadUsernconfirmedReports,
  };
};

export const useLoadStudyGroupApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadStudyGroupApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadStudyGroupApi = async (accountId?: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadStudyGroupApi,
  };
};

export const useLoadLegalCurriculumMstListApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadLegalCurriculumMstListApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadLegalCurriculumMstList = async (accountId?: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadLegalCurriculumMstList,
  };
};

export const useLoadLegalCurriculumMstApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadLegalCurriculumMstApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadLegalCurriculumMst = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isLoading,
    callLoadLegalCurriculumMst,
  };
};

export const useLoadLegalCurriculumTemplateApi = () => {
  const { isExecuting: isLoading, callApi: callLoadLegalCurriculumTemplate } = useTokenApiNoArgs({
    api: loadLegalCurriculumTemplateApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isLoading,
    callLoadLegalCurriculumTemplate,
  };
};

export const useLoadLegalCurriculumListApi = () => {
  const { isExecuting: isLoading, callApi: callLoadLegalCurriculumListApi } = useTokenApi({
    api: loadLegalCurriculumListApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadLegalCurriculumList = async (baseYear: number) => {
    return await callLoadLegalCurriculumListApi({ baseYear });
  };
  return {
    isLoading,
    callLoadLegalCurriculumList,
  };
};

export const useLoadLegalCurriculumYearApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadLegalCurriculumYearApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callLoadLegalCurriculumYearApi = async (accountId?: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadLegalCurriculumYearApi,
  };
};

export const useLoadLegalCurriculumThemeApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadLegalCurriculumThemeApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadLegalCurriculumThemeApi = async (baseYear: number) => {
    return await callApi({ baseYear });
  };
  return {
    isLoading,
    callLoadLegalCurriculumThemeApi,
  };
};

export const useLoadCareerExclusionApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadCareerExclusionApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadCareerExclusionApi = async (studyGroupId: number, accountId?: string) => {
    return await callApi({ studyGroupId, accountId });
  };
  return {
    isLoading,
    callLoadCareerExclusionApi,
  };
};

export const useLoadAnnounceHomeApi = () => {
  const { callApi: callLoadAnnounceHomeApi } = useTokenApiNoArgs({
    api: loadAnnounceHomeApi,
    // メニュー上で非同期で読み込むため、不要な描画が走らないようbannerを使わない
    // 合わせて、useStateも利用しない
    noChangeState: true,
  });
  return {
    callLoadAnnounceHomeApi: callLoadAnnounceHomeApi,
  };
};

export const useLoadAnnounceListApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAnnounceListApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAnnounceListApi = async (unreadOnly: boolean, page: number, limit: number) => {
    return await callApi({ unreadOnly, page, limit });
  };
  return {
    isLoading,
    callLoadAnnounceListApi,
  };
};

export const useLoadAnnounceDtlApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAnnounceDtlApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAnnounceDtlApi = async (id: number, targetType: announceTargetType) => {
    return await callApi({ id, targetType });
  };
  return {
    isLoading,
    callLoadAnnounceDtlApi,
  };
};

export const useLoadAnnounceMstListApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAnnounceMstListApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callLoadAnnounceMstListApi = async (page: number, limit: number) => {
    return await callApi({ page, limit });
  };
  return {
    isLoading,
    callLoadAnnounceMstListApi,
  };
};

export const useLoadAnnounceMstDtlApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAnnounceMstDtlApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadAnnounceMstDtlApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isLoading,
    callLoadAnnounceMstDtlApi,
  };
};

export const useLoadCategoryGenreApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApiNoArgs({
    api: loadCategoryGenreApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadCategoryGenreApi = async () => {
    return await callApi();
  };
  return {
    isLoading,
    callLoadCategoryGenreApi,
  };
};

export const useLoadNextActionApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadNextActionApi,
    // メニュー上で非同期で読み込むため、不要な描画が走らないようbannerを使わない
    noChangeState: true,
  });

  const callLoadNextActionApi = async (contentsId: number, baseYear: number, baseMonth: number) => {
    return await callApi({ contentsId, baseYear, baseMonth });
  };

  return {
    isLoading,
    callLoadNextActionApi,
  };
};

export const useLoadUserAdditionalInfoApi = () => {
  const { isExecuting: isLoading, callApi: callLoadUserAdditionalInfoApi } = useTokenApiNoArgs({
    api: loadUserAdditionalInfoApi,
    // メニュー上で非同期で読み込むため、不要な描画が走らないようbannerを使わない
    noChangeState: true,
  });
  return {
    isLoading,
    callLoadUserAdditionalInfoApi,
  };
};

export const useLoadInitialTutorialApi = () => {
  const { isExecuting: isLoading, callApi: callLoadInitialTutorialApi } = useTokenApiNoArgs({
    api: loadInitialTutorialApi,
  });

  return {
    isLoading,
    callLoadInitialTutorialApi,
  };
};

export const useLoadInitialTutorialProgressApi = () => {
  const { isExecuting: isLoading, callApi: callLoadInitialTutorialProgressApi } = useTokenApiNoArgs({
    api: loadInitialTutorialProgressApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isLoading,
    callLoadInitialTutorialProgressApi,
  };
};

export const useLoadImportUserHistoryApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadImportUserHistoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadImportUserHistoryApi = async (accountId?: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadImportUserHistoryApi,
  };
};

export const useLoadCareerHistoryApi = () => {
  const { isExecuting: isLoading, callApi: callLoadCareerHistoryApi } = useTokenApiNoArgs({
    api: loadCareerHistoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isLoading,
    callLoadCareerHistoryApi,
  };
};

export const useLoadCareerCertificateOfCompletionApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadCareerCertificateOfCompletionApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadCareerCertificateOfCompletionApi = async (careerType: careerType, level: number) => {
    return await callApi({ careerType, level });
  };
  return {
    isLoading,
    callLoadCareerCertificateOfCompletionApi,
  };
};

export const useLoadCareerThemeApi = () => {
  const { isExecuting: isLoading, callApi: callLoadCareerThemeApi } = useTokenApiNoArgs({
    api: loadCareerThemeApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  return {
    isLoading,
    callLoadCareerThemeApi,
  };
};

export const useLoadBreadcrumbApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadBreadcrumb,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callLoadBreadcrumbApi = async (accountOriginalFlg: boolean, themeId: number, contentsId?: number) => {
    return await callApi({ accountOriginalFlg, themeId, contentsId });
  };
  return {
    isLoading,
    callLoadBreadcrumbApi,
  };
};

export const useLoadAccountInviteApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountInviteApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadAccountInviteApi = async (accountId: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadAccountInviteApi,
  };
};

export const useLoadAccountByCorporationAccountApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountByCorporationAccountApi,
  });

  const callLoadAccountByCorporationAccountApi = async (accountId: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadAccountByCorporationAccountApi,
  };
};

export const useLoadStudyGroupByAccountIdApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadStudyGroupByAccountIdApi,
  });

  const callLoadStudyGroupByAccountIdApi = async (accountId: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadStudyGroupByAccountIdApi,
  };
};

export const useLoadLegalCurriculumDocumentStatus = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadLegalCurriculumDocumentStatus,
  });

  const callLoadLegalCurriculumDocumentStatus = async (legalCurriculumId: number) => {
    return await callApi({ legalCurriculumId });
  };
  return {
    isLoading,
    callLoadLegalCurriculumDocumentStatus,
  };
};

export const useLoadContentsBaseViewingApi = () => {
  const { isExecuting: isLoading, callApi: callLoadContentsBaseViewingApi } = useTokenApiNoArgs({
    api: loadContentsBaseViewingApi,
    customErrorHandling: errorMessageHandling,
  });
  return {
    isLoading,
    callLoadContentsBaseViewingApi,
  };
};

export const useLoadContentsApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadContentsApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadContentsApi = async (name: string) => {
    return await callApi({ name });
  };
  return {
    isLoading,
    callLoadContentsApi,
  };
};

export const useLoadPackageAccountApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadPackageAccountApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadPackageAccountApi = async (accountId: string, includePrivate: boolean) => {
    return await callApi({ accountId, includePrivate });
  };
  return {
    isLoading,
    callLoadPackageAccountApi,
  };
};

export const useLoadPackageListApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadPackageListApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadPackageListApi = async (includePrivate: boolean) => {
    return await callApi({ includePrivate });
  };
  return {
    isLoading,
    callLoadPackageListApi,
  };
};

export const useLoadPackageOneApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadPackageOneApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadPackageOneApi = async (packageId: number) => {
    return await callApi({ packageId });
  };
  return {
    isLoading,
    callLoadPackageOneApi,
  };
};

export const useLoadMypagePackageApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApiNoArgs({
    api: loadMypagePackageApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadMypagePackageApi = async () => {
    return await callApi();
  };
  return {
    isLoading,
    callLoadMypagePackageApi,
  };
};

export const useLoadMypagePackageDtlApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadMypagePackageDtlApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadMypagePackageDtlApi = async (packageId: number) => {
    return await callApi({ packageId });
  };
  return {
    isLoading,
    callLoadMypagePackageDtlApi,
  };
};

export const useLoadAccountPortalPackageApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPortalPackageApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadAccountPortalPackageApi = async (accountId?: string) => {
    return await callApi({ accountId });
  };
  return {
    isLoading,
    callLoadAccountPortalPackageApi,
  };
};

export const useLoadAccountPortalPackageDtlApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadAccountPortalPackageDtlApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadAccountPortalPackageDtlApi = async (packageId: number, accountId?: string) => {
    return await callApi({ packageId, accountId });
  };
  return {
    isLoading,
    callLoadAccountPortalPackageDtlApi,
  };
};

export const useLoadCorporationAccountForSearchApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApiNoArgs({
    api: loadCorporationAccountForSearchApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadCorporationAccountForSearchApi = async () => {
    return await callApi();
  };
  return {
    isLoading,
    callLoadCorporationAccountForSearchApi,
  };
};

export const useLoadPackageForSearchApi = () => {
  const { isExecuting: isLoading, callApi } = useTokenApi({
    api: loadPackageForSearchApi,
    customErrorHandling: errorMessageHandling,
  });

  const callLoadPackageForSearchApi = async (includePrivate: boolean) => {
    return await callApi({ includePrivate });
  };
  return {
    isLoading,
    callLoadPackageForSearchApi,
  };
};

export const useRegisterBookmarkApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerBookmarkApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callRegisterBookmarkApi = async (accountOriginalFlg: boolean, contentsId: number, packageId: number) => {
    return await callApi({ accountOriginalFlg, contentsId, packageId });
  };
  return {
    isSaving,
    // 本来はcontextData,
    callRegisterBookmarkApi,
  };
};

export const useRegisterReportApi = () => {
  const { dispatch: bannerDispatch } = useBannerContext();
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerReportApi,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customErrorHandling: (e: any) => {
      if (isBadRequestException(e)) {
        // 重複時はエラーをスルーする
        return {
          isSuccess: false,
          duplicate: true,
        };
      } else {
        bannerDispatch(isError(MESSAGE.API_GENERAL_ERROR_TITLE, e.message));
        return {
          isSuccess: false,
        };
      }
    },
  });

  const callRegisterReportApi = async (accountOriginalFlg: boolean, themeId: number, report: string) => {
    return await callApi({ accountOriginalFlg, themeId, report });
  };
  return {
    isSaving,
    callRegisterReportApi,
  };
};

export const useRegisterTestApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerTestApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterTestApi = async (params: apiRegisterTest) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterTestApi,
  };
};

export const useRegisterViewApi = () => {
  const { dispatch: bannerDispatch } = useBannerContext();
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerViewApi,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customErrorHandling: (e: any) => {
      if (isBadRequestException(e)) {
        // 重複時はエラーをスルーする
        return {
          isSuccess: false,
          duplicate: true,
        };
      } else {
        bannerDispatch(isError(MESSAGE.API_GENERAL_ERROR_TITLE, e.message));
        return {
          isSuccess: false,
        };
      }
    },
  });

  const callRegisterViewApi = async (params: apiRegisterView) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterViewApi,
  };
};

export const useRegisterInquiryApi = () => {
  const { isExecuting: isSaving, callApi: callRegisterInquiryApi } = useTokenApi({
    api: registerInquiryApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  return {
    isSaving,
    callRegisterInquiryApi,
  };
};

export const useRegisterInquiryResponseDmApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerInquiryResponseDmApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterInquiryResponseDmApi = async (params: apiRegisterInquiryResponse) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterInquiryResponseDmApi,
  };
};

export const useRegisterInquiryTempResponseDmApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerInquiryTempResponseDmApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterInquiryTempResponseDmApi = async (params: apiRegisterInquiryResponse) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterInquiryTempResponseDmApi,
  };
};

export const useRegisterInquiryWorkCommentApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerInquiryWorkCommentApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterInquiryWorkCommentApi = async (params: apiRegisterInquiryWorkComment) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterInquiryWorkCommentApi,
  };
};

export const useRegisterInquiryResponseUserApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerInquiryResponseUserApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterInquiryResponseUserApi = async (params: apiRegisterInquiryResponse) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterInquiryResponseUserApi,
  };
};

export const useRegisterInquiryReadedApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerInquiryReadedApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterInquiryReadedApi = async (inquiryId: number) => {
    return await callApi({ id: inquiryId });
  };
  return {
    isSaving,
    callRegisterInquiryReadedApi,
  };
};

export const useRegisterAccountApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerAccountApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callRegisterAccountApi = async (params: apiRegisterAccountRequest) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterAccountApi,
  };
};

export const useRegisterCorporationAccountApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerCorporationAccountApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterCorporationAccountApi = async (params: {
    id: string;
    name: string;
    dmAdminUrl: string;
    usageStartDate: Date | string;
    usageEndDate: string | null;
    demoFlg: boolean;
    accountIds: string[];
  }) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterCorporationAccountApi,
  };
};

export const useRegisterUserApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerUserApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterUserApi = async (items: apiRegisterUser) => {
    return await callApi({ items });
  };
  return {
    isSaving,
    callRegisterUserApi,
  };
};

export const useRegisterCategoryApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerCategoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterCategoryApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    categoryGroupId: number,
    name: string,
    ord: number,
    description: string,
    image: string
  ) => {
    return await callApi({ accountOriginalFlg, accountId, categoryGroupId, name, ord, description, image });
  };
  return {
    isSaving,
    callRegisterCategoryApi,
  };
};

export const useRegisterThemeApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerThemeApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterThemeApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    categoryId: number,
    categoryGenreId: number,
    name: string,
    ord: number,
    impressionFlg: boolean,
    description: string,
    image: string,
    documentPath: string
  ) => {
    return await callApi({
      accountOriginalFlg,
      accountId,
      categoryId,
      categoryGenreId,
      name,
      ord,
      impressionFlg,
      description,
      image,
      documentPath,
    });
  };
  return {
    isSaving,
    callRegisterThemeApi,
  };
};

export const useRegisterContentsWorkApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerContentsWorkApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callRegisterContentsWorkApi = async (params: apiRegisterContentsWork) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterContentsWorkApi,
  };
};

export const useRegisterTestTemplateWorkApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerTestTemplateWorkApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterTestTemplateWorkApi = async (testTemplateWorks: apiTestWorkItem) => {
    return await callApi(testTemplateWorks);
  };
  return {
    isSaving,
    callRegisterTestTemplateWorkApi,
  };
};

export const useRegisterContentsMediaApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerContentsMediaApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterContentsMediaApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    name: string,
    contentsMediaConvertId: number,
    playtime: number,
    thumbnailType: string,
    thumbnailPath: string
  ) => {
    return await callApi({
      accountOriginalFlg,
      accountId,
      name,
      contentsMediaConvertId,
      playtime,
      thumbnailType,
      thumbnailPath,
    });
  };
  return {
    isSaving,
    callRegisterContentsMediaApi,
  };
};

export const useRegisterTestTemplateCategoryApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerTestTemplateCategoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterTestTemplateCategoryApi = async (level: number) => {
    return await callApi({ level });
  };
  return {
    isSaving,
    callRegisterTestTemplateCategoryApi,
  };
};

export const useRegisterTestCategoryApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerTestCategoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterTestCategoryApi = async (testTemplateCategoryId: number, testAnswers: testAnswerType[]) => {
    return await callApi({ testTemplateCategoryId, results: testAnswers });
  };
  return {
    isSaving,
    callRegisterTestCategoryApi,
  };
};

export const useRegisterFrontErrorApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerFrontError,
  });

  const callRegisterFrontErrortApi = async (menuUrl: string, errorMessage: string) => {
    return await callApi({ menuUrl, errorMessage });
  };
  return {
    isSaving,
    callRegisterFrontErrortApi,
  };
};

export const useRegisterStudyGroupApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerStudyGroupApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterStudyGroupApi = async (name: string, accountId?: string) => {
    return await callApi({ name, accountId });
  };
  return {
    isSaving,
    callRegisterStudyGroupApi,
  };
};

export const useRegisterLegalCurriculumApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerLegalCurriculumApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callRegisterLegalCurriculumApi = async (
    startYear: number,
    legalCurriculumTemplateId: number,
    studyGroupId: number,
    dtls: {
      month: number;
      themeId: number;
      monthOrd: number;
      themeOrd: number;
    }[],
    accountId?: string
  ) => {
    return await callApi({ startYear, legalCurriculumTemplateId, studyGroupId, dtls, accountId });
  };
  return {
    isSaving,
    callRegisterLegalCurriculumApi,
  };
};

export const useRegisterLegalCurriculumStudiedForceApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerLegalCurriculumStudiedForceApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterLegalCurriculumStudiedForceApi = async (
    legalCurriculumId: number,
    userId: number,
    month: number
  ) => {
    return await callApi({ legalCurriculumId, userId, month });
  };
  return {
    isSaving,
    callRegisterLegalCurriculumStudiedForceApi,
  };
};

export const useRegisterCareerExclusionApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerCareerExclusionApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterCareerExclusionApi = async (
    studyGroupId: number,
    careerType: careerType | string,
    level: number,
    themeIds: number[],
    accountId: string | undefined
  ) => {
    return await callApi({ studyGroupId, careerType: careerType as careerType, level, themeIds, accountId });
  };
  return {
    isSaving,
    callRegisterCareerExclusionApi,
  };
};

export const useRegisterAnnounceApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerAnnounceApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterAnnounceApi = async (
    announceType: announceType,
    htmlFlg: boolean | number,
    importantFlg: boolean | number,
    title: string,
    contents: string,
    target: announceTargetType,
    startDate: Date | string | null,
    bannerEndDate: Date | string | null,
    fileObjectKey: string,
    fileName: string | undefined,
    linkLabel: string | undefined
  ) => {
    return await callApi({
      announceType,
      htmlFlg,
      importantFlg,
      title,
      contents,
      target,
      startDate,
      bannerEndDate,
      fileObjectKey,
      fileName,
      linkLabel,
    });
  };
  return {
    isSaving,
    callRegisterAnnounceApi,
  };
};

export const useRegisterAnnounceReadedApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerAnnounceReadedApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterAnnounceReadedApi = async (ids: { id: number; targetType: announceTargetType }[]) => {
    return await callApi({ ids });
  };
  return {
    isSaving,
    callRegisterAnnounceReadedApi,
  };
};

export const useRegisterAnnounceClosedApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerAnnounceClosedApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterAnnounceClosedApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isSaving,
    callRegisterAnnounceClosedApi,
  };
};

export const useRegisterCategoryGenreApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerCategoryGenreApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterCategoryGenreApi = async (name: string, ord: number) => {
    return await callApi({ name, ord });
  };
  return {
    isSaving,
    callRegisterCategoryGenreApi,
  };
};

export const useRegisterContentsEvaluationApi = () => {
  const { callApi } = useTokenApi({
    api: registerContentsEvaluationApi,
    customErrorHandling: useErrorBannerHandling(),
    noChangeState: true,
  });

  const callRegisterContentsEvaluationApi = async (contentsId: number, helpful: boolean) => {
    return await callApi({ contentsId, helpful });
  };
  return {
    callRegisterContentsEvaluationApi,
  };
};

export const useRegisterMailAddressApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerMailAddressApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterMailAddressApi = async (mailAddress: string) => {
    return await callApi({ mailAddress });
  };
  return {
    isSaving,
    callRegisterMailAddressApi,
  };
};

export const useRegisterUserAdditionalInfoApi = () => {
  const { isExecuting: isSaving, callApi: callRegisterUserAdditionalInfoApi } = useTokenApi({
    api: registerUserAdditionalInfoApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callRegisterAdditionalUserInfoApi = async (params: {
    lastName: string;
    firstName: string;
    occupation: additionalInfoOccupationType;
    experienceYear: additionalInfoExperienceYearType;
    joinedCompanyDate?: Date | string | null;
    birthDate?: Date | string | null;
  }) => {
    return await callRegisterUserAdditionalInfoApi(params);
  };
  return {
    isSaving,
    callRegisterAdditionalUserInfoApi,
  };
};

export const useRegisterCareerApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerCareerApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterCareerApi = async (
    careerType: string,
    level: number,
    name: string,
    description: string,
    image: string
  ) => {
    return await callApi({ careerType: careerType as careerType, level, name, description, image });
  };
  return {
    isSaving,
    callRegisterCareerApi,
  };
};

export const useRegisterCareerThemeWorkApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerCareerThemeWorkApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRegisterCareerThemeWorkApi = async (
    careerType: careerType | string,
    level: number,
    dtls: { ord: number; themeId: number }[] | undefined
  ) => {
    if (!dtls) {
      return {
        isSuccess: false,
      };
    }
    return await callApi({ careerType: careerType as careerType, level, dtls });
  };
  return {
    isSaving,
    callRegisterCareerThemeWorkApi,
  };
};

export const useRegisterUserInviteApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerUserInvite,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterUserInviteApi = async (userInvite: apiRegisterUserInvite) => {
    return await callApi({ userInvite });
  };
  return {
    isSaving,
    callRegisterUserInviteApi,
  };
};

export const useRegisterAccountInviteApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerAccountInvite,
    customErrorHandling: errorMessageHandling,
  });
  const callRegisterAccountInviteApi = async (accountId: string) => {
    return await callApi({ accountId });
  };
  return {
    isSaving,
    callRegisterAccountInviteApi,
  };
};

export const useRegisterReInviteApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerReInvite,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterReInviteApi = async (inviteUlid: string) => {
    return await callApi({ inviteUlid });
  };
  return {
    isSaving,
    callRegisterReInviteApi,
  };
};

export const useRegisterChangeLoginStartApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerChangeLoginStartApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterChangeLoginStartApi = async (
    loginInfo:
      | {
          loginType: "PASSWORD";
          password: string;
        }
      | { loginType: "EMAIL"; mailAddress: string }
      | { loginType: "SMS"; phoneNumber: string }
  ) => {
    return await callApi({ loginInfo });
  };
  return {
    isSaving,
    callRegisterChangeLoginStartApi,
  };
};

export const useRegisterPersonnelChangeApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerPersonnelChangeApi,
  });

  const callRegisterPersonnelChangeApi = async (params: apiRegisterPersonnelChange) => {
    const respose = await callApi(params);
    if (respose.isSuccess) {
      return respose;
    }
    // 呼び出し側が存在しないerrorMessageを参照する前提のロジックだったため、既存保証でundefinedを返す

    return {
      isSuccess: false,
      result: {
        errorMessage: undefined,
      },
    };
  };
  return {
    isSaving,
    callRegisterPersonnelChangeApi,
  };
};

export const useRegisterPackageApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerPackageApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterPackageApi = async (params: apiRegisterPackage) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterPackageApi,
  };
};

export const useRegisterPackageAccountApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerPackageAccountApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterPackageAccountApi = async (params: { accountId: string; packageIds: number[] }) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterPackageAccountApi,
  };
};

export const useRegisterBulkPackageAccountApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: registerBulkPackageAccountApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRegisterBulkPackageAccountApi = async (params: {
    packageIds: number[] | undefined;
    linkType: packageAccountBulkType | undefined;
    name: string | undefined;
    validOnly: boolean | undefined;
    id: string | undefined;
    demo: searchDemoType | undefined;
    package: searchPackageType | undefined;
    plan: searchAccountPlanType | undefined;
    validUsageEndDateOnly: boolean | undefined;
    corporationId: string | undefined;
    packageIdsFilter: number[] | undefined;
    packageFilterType: searchPackageFilterType | undefined;
  }) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callRegisterBulkPackageAccountApi,
  };
};

export const useCopyAnnounceApi = () => {
  const { isExecuting: isCopying, callApi } = useTokenApi({
    api: copyAnnounceApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callCopyAnnounceApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isCopying,
    callCopyAnnounceApi,
  };
};

export const useGenerateProfileUploadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateProfileUploadUrlApi,
  });

  const callGenerateProfileUploadUrlApi = async (fileName: string) => {
    return await callApi({ fileName });
  };
  return {
    isSaving,
    callGenerateProfileUploadUrlApi,
  };
};

export const useGenerateMediaUploadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateMediaUploadUrlApi,
  });

  const callGenerateMediaUploadUrlApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    fileName: string
  ) => {
    return await callApi({ accountOriginalFlg, accountId, fileName });
  };
  return {
    isSaving,
    callGenerateMediaUploadUrlApi,
  };
};

export const useGenerateMediaDownloadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateMediaDownloadUrlApi,
  });

  const callGenerateMediaDownloadUrlApi = async (contentsMediaConvertId: number) => {
    return await callApi({ contentsMediaConvertId });
  };
  return {
    isSaving,
    callGenerateMediaDownloadUrlApi,
  };
};

export const useGenerateThumbnailUploadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateThumbnailUploadUrlApi,
  });

  const callGenerateThumbnailUploadUrlApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    fileName: string
  ) => {
    return await callApi({ accountOriginalFlg, accountId, fileName });
  };
  return {
    isSaving,
    callGenerateThumbnailUploadUrlApi,
  };
};

export const useGenerateAnnounceFileUploadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateAnnounceFileUploadUrlApi,
  });

  const callGenerateAnnounceFileUploadUrlApi = async (fileName: string) => {
    return await callApi({ fileName });
  };
  return {
    isSaving,
    callGenerateAnnounceFileUploadUrlApi,
  };
};

export const useGenerateAnnounceFileDownloadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateAnnounceFileDownloadUrlApi,
  });

  const callGenerateAnnounceFileDownloadUrlApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isSaving,
    callGenerateAnnounceFileDownloadUrlApi,
  };
};

export const useGenerateImportUserUploadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateImportUserUploadUrlApi,
  });
  const callGenerateImportUserUploadUrlApi = async (fileName: string, accountId?: string) => {
    return await callApi({ fileName, accountId });
  };
  return {
    isSaving,
    callGenerateImportUserUploadUrlApi,
  };
};

export const useGenerateImportUserDownloadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateImportUserDownloadUrlApi,
  });

  const callGenerateImportUserDownloadUrlApi = async (importFileId: number) => {
    return await callApi({ importFileId });
  };
  return {
    isSaving,
    callGenerateImportUserDownloadUrlApi,
  };
};

export const useGenerateSubtitlesUploadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateSubtitlesUploadUrlApi,
  });

  const callGenerateSubtitlesUploadUrlApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    fileName: string
  ) => {
    return await callApi({ accountOriginalFlg, accountId, fileName });
  };
  return {
    isSaving,
    callGenerateSubtitlesUploadUrlApi,
  };
};

export const useGenerateSubtitlesDownloadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateSubtitlesDownloadUrlApi,
  });
  const callGenerateSubtitlesDownloadUrlApi = async (
    accountOriginalFlg: boolean,
    work: boolean,
    id: number,
    language: string
  ) => {
    return await callApi({ accountOriginalFlg, work, id, language });
  };
  return {
    isSaving,
    callGenerateSubtitlesDownloadUrlApi,
  };
};

export const useGenerateDocumentUploadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateDocumentUploadUrlApi,
  });

  const callGenerateDocumentUploadUrlApi = async (fileName: string) => {
    return await callApi({ fileName });
  };
  return {
    isSaving,
    callGenerateDocumentUploadUrlApi,
  };
};

export const useGenerateDocumentDownloadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateDocumentDownloadUrlApi,
  });

  const callGenerateDocumentDownloadUrlApi = async (themeId: number) => {
    return await callApi({ themeId });
  };
  return {
    isSaving,
    callGenerateDocumentDownloadUrlApi,
  };
};

export const useGenerateLegalCurriculumDocumentApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateLegalCurriculumDocumentApi,
  });

  const callGenerateLegalCurriculumDocumentApi = async (legalCurriculumId: number) => {
    return await callApi({ legalCurriculumId });
  };
  return {
    isSaving,
    callGenerateLegalCurriculumDocumentApi,
  };
};

export const useGenerateLegalCurriculumDocumentDownloadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateLegalCurriculumDocumentDownloadUrlApi,
  });

  const callGenerateLegalCurriculumDocumentDownloadUrlApi = async (legalCurriculumId: number) => {
    return await callApi({ legalCurriculumId });
  };
  return {
    isSaving,
    callGenerateLegalCurriculumDocumentDownloadUrlApi,
  };
};

export const useGenerateInquiryUploadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateInquiryUploadUrlApi,
  });
  const callGenerateInquiryUploadUrlApi = async (fileName: string) => {
    return await callApi({ fileName });
  };
  return {
    isSaving,
    callGenerateInquiryUploadUrlApi,
  };
};

export const useGenerateInquiryDownloadUrlApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: generateInquiryDownloadUrlApi,
  });

  const callGenerateInquiryDownloadUrlApi = async (id: number, work: boolean) => {
    return await callApi({ id, work });
  };
  return {
    isSaving,
    callGenerateInquiryDownloadUrlApi,
  };
};

export const useImportUserApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: importUserApi,
    customErrorHandling: errorMessageHandling,
  });

  const callImportUserApi = async (importFileId: number) => {
    return await callApi({ importFileId });
  };
  return {
    isSaving,
    callImportUserApi,
  };
};

export const useUploadFile = () => {
  const { isExecuting: isUploading, callApi } = useTokenApi({
    api: uploadS3Api,
  });

  const callUploadFile = async (
    uploadPreSignedURL: string,
    file: File,
    progress?: React.Dispatch<React.SetStateAction<number>>
  ) => {
    return await callApi({ uploadPreSignedURL, file, contentType: file.type, progress });
  };
  return {
    isUploading,
    callUploadFile,
  };
};

export const useReleaseContentsApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: releaseContentsApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callReleaseContentsApi = async (params: apiReleaseContents) => {
    return await callApi(params);
  };
  return {
    isSaving,
    callReleaseContentsApi,
  };
};

export const useReleaseContentsBulkApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: releaseContentsBulkApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callReleaseContentsBulkApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    categoryId: number,
    themeId: number
  ) => {
    return await callApi({ accountOriginalFlg, accountId, categoryId, themeId });
  };
  return {
    isSaving,
    callReleaseContentsBulkApi,
  };
};

export const useReleaseAnnounceApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: releaseAnnounceApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callReleaseAnnounceApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isSaving,
    callReleaseAnnounceApi,
  };
};

export const useReleaseCareerApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: releaseCareerThemeApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callReleaseCareerThemeApi = async (careerType: careerType | string, level: number) => {
    return await callApi({ careerType: careerType as careerType, level });
  };
  return {
    isSaving,
    callReleaseCareerThemeApi,
  };
};

export const useReleasePackageApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: releasePackageApi,
    customErrorHandling: errorMessageHandling,
  });

  const callReleasePackageApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isSaving,
    callReleasePackageApi,
  };
};

export const useCloseContentsApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: closeContentsApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callCloseContentsApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    categoryId: number,
    themeId: number,
    contentsId: number
  ) => {
    return await callApi({ accountOriginalFlg, accountId, categoryId, themeId, contentsId });
  };
  return {
    isSaving,
    callCloseContentsApi,
  };
};

export const useCloseAnnounceApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: closeAnnounceApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callCloseAnnounceApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isSaving,
    callCloseAnnounceApi,
  };
};

export const useClosePackageApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: closePackageApi,
    customErrorHandling: errorMessageHandling,
  });

  const callClosePackageApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isSaving,
    callClosePackageApi,
  };
};

export const useRereleaseContentsApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: rereleaseContentsApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRereleaseContentsApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    contentsId: number
  ) => {
    return await callApi({ accountOriginalFlg, accountId, id: contentsId });
  };
  return {
    isSaving,
    callRereleaseContentsApi,
  };
};

export const useRereleasePackageApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: rereleasePackageApi,
    customErrorHandling: errorMessageHandling,
  });

  const callRereleasePackageApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isSaving,
    callRereleasePackageApi,
  };
};

export const useRestartCategoryApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: restartCategoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRestartCategoryApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    categoryId: number | string
  ) => {
    return await callApi({ accountOriginalFlg, accountId, id: categoryId as number });
  };
  return {
    isSaving,
    callRestartCategoryApi,
  };
};

export const useRestartThemeApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: restartThemeApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callRestartThemeApi = async (accountOriginalFlg: boolean, accountId: string | undefined, themeId: number) => {
    return await callApi({ accountOriginalFlg, accountId, id: themeId });
  };
  return {
    isSaving,
    callRestartThemeApi,
  };
};

export const useRestartContentsApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: restartContentsApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callRestartContentsApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    contentsId: number
  ) => {
    return await callApi({ accountOriginalFlg, accountId, id: contentsId });
  };
  return {
    isSaving,
    callRestartContentsApi,
  };
};

export const useStartPackage = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: startPackageApi,
    customErrorHandling: errorMessageHandling,
  });

  const callStartPackageApi = async (packageId: number) => {
    return await callApi({ packageId });
  };
  return {
    isSaving,
    callStartPackageApi,
  };
};

export const useCloseContentsBulkApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: closeContentsBulkApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callCloseContentsBulkApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    categoryId: number,
    themeId: number
  ) => {
    return await callApi({ accountOriginalFlg, accountId, categoryId, themeId });
  };
  return {
    isSaving,
    callCloseContentsBulkApi,
  };
};

export const useUpdateInquiryApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateInquiryApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateInquiryApi = async (params: apiUpdateInquiry) => {
    return await callApi(params);
  };
  return {
    isUpdating,
    callUpdateInquiryApi,
  };
};

export const useUpdateInquirySolvedApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateInquirySolvedApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateInquirySolvedApi = async (inquiryId: number) => {
    return await callApi({ inquiryId });
  };
  return {
    isUpdating,
    callUpdateInquirySolvedApi,
  };
};

export const useUpdateInquirySolvedDmApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateInquirySolvedDmApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateInquirySolvedDmApi = async (inquiryId: number) => {
    return await callApi({ inquiryId });
  };
  return {
    isUpdating,
    callUpdateInquirySolvedDmApi,
  };
};

export const useUpdateInquiryUnsolvedApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateInquiryUnsolvedApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateInquiryUnsolvedApi = async (inquiryId: number) => {
    return await callApi({ inquiryId });
  };
  return {
    isUpdating,
    callUpdateInquiryUnsolvedApi,
  };
};

export const useUpdateInquiryUnsolvedDmApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateInquiryUnsolvedDmApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateInquiryUnsolvedDmApi = async (inquiryId: number) => {
    return await callApi({ inquiryId });
  };
  return {
    isUpdating,
    callUpdateInquiryUnsolvedDmApi,
  };
};

export const useUpdateReportApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateReportApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateReportApi = async (accountOriginalFlg: boolean, reportId: number, report: string) => {
    return await callApi({ accountOriginalFlg, reportId, report });
  };
  return {
    isUpdating,
    callUpdateReportApi,
  };
};

export const useUpdateRestartViewApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateRestartViewApi,
  });
  const callUpdateRestartViewApi = async (params: apiUpdateRestartView) => {
    return await callApi(params);
  };
  return {
    isUpdating,
    callUpdateRestartViewApi,
  };
};

export const useUpdateStopViewApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateStopViewApi,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customErrorHandling: (e: any) => {
      return {
        isSuccess: false,
        result: { errorMessage: e.message },
      };
    },
  });

  const callUpdateStopViewApi = async (
    accountOriginalFlg: boolean,
    viewingHistoryContentsId: number,
    replayTime: number
  ) => {
    return await callApi({ accountOriginalFlg, viewingHistoryContentsId, replayTime });
  };
  return {
    isUpdating,
    callUpdateStopViewApi,
  };
};

export const useUpdateRestartUserApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateRestartUserApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateRestartUserApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isUpdating,
    callUpdateRestartUserApi,
  };
};

export const useUpdateRestartAccountApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateRestartAccountApi,
  });
  const callUpdateRestartAccountApi = async (id: string) => {
    return await callApi({ id });
  };
  return {
    isUpdating,
    callUpdateRestartAccountApi,
  };
};

export const useUpdateRestartCorporationAccountApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateRestartCorporationAccountApi,
  });

  const callUpdateRestartCorporationAccountApi = async (id: string) => {
    return await callApi({ id });
  };
  return {
    isUpdating,
    callUpdateRestartCorporationAccountApi,
  };
};

export const useUpdateCompleteViewApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateCompleteViewApi,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customErrorHandling: (e: any) => {
      return {
        isSuccess: false,
        result: { errorMessage: e.message },
      };
    },
  });

  const callUpdateCompleteViewApi = async (params: apiUpdateCompleteView) => {
    return await callApi(params);
  };
  return {
    isUpdating,
    callUpdateCompleteViewApi,
  };
};

export const useUpdateReportStatusConfirmdApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateReportStatusConfirmdApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateReportStatusConfirmApi = async (accountOriginalFlg: boolean, reportId: number) => {
    return await callApi({ accountOriginalFlg, reportId });
  };
  return {
    isUpdating,
    callUpdateReportStatusConfirmApi,
  };
};

export const useUpdateReportStatusUnconfirmdApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateReportStatusUnconfirmApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateReportStatusUnconfirmApi = async (accountOriginalFlg: boolean, reportId: number) => {
    return await callApi({ accountOriginalFlg, reportId });
  };
  return {
    isUpdating,
    callUpdateReportStatusUnconfirmApi,
  };
};

export const useUpdateInquiryResponseDmApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateInquiryResponseDmApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateInquiryResponseDmApi = async (params: apiUpdateInquiryResponse) => {
    return await callApi(params);
  };
  return {
    isUpdating,
    callUpdateInquiryResponseDmApi,
  };
};

export const useUpdateInquiryResponseUserApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateInquiryResponseUserApi,
    customErrorHandling: errorMessageHandling,
  });
  const callUpdateInquiryResponseUserApi = async (params: apiUpdateInquiryResponse) => {
    return await callApi(params);
  };
  return {
    isUpdating,
    callUpdateInquiryResponseUserApi,
  };
};

export const useUpdateAccountApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateAccountApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateAccountApi = async (params: apiAccount) => {
    return await callApi(params);
  };
  return {
    isUpdating,
    callUpdateAccountApi,
  };
};

export const useUpdateCorporationAccountApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateCorporationAccountApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateCorporationAccountApi = async (params: {
    id: string;
    name: string;
    dmAdminUrl: string;
    usageStartDate: string;
    usageEndDate: string | null;
    demoFlg: boolean;
    accountIds: string[];
  }) => {
    return await callApi(params);
  };
  return {
    isUpdating,
    callUpdateCorporationAccountApi,
  };
};

export const useUpdateUserApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateUserApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateUserApi = async (items: apiUpdateUser) => {
    return await callApi(items);
  };
  return {
    isUpdating,
    callUpdateUserApi,
  };
};

export const useUpdateCategoryApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateCategoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateCategoryApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    id: number | string,
    categoryGroupId: number,
    name: string,
    ord: number,
    description: string,
    image: string
  ) => {
    return await callApi({
      accountOriginalFlg,
      accountId,
      id: id as number,
      categoryGroupId,
      name,
      ord,
      description,
      image,
    });
  };
  return {
    isUpdating,
    callUpdateCategoryApi,
  };
};

export const useUpdateCategoryFixedApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateCategoryFixedApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateCategoryFixedApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    id: number | string,
    name: string,
    ord: number,
    description: string,
    image: string
  ) => {
    return await callApi({ accountOriginalFlg, accountId, id: id as number, name, ord, description, image });
  };
  return {
    isUpdating,
    callUpdateCategoryFixedApi,
  };
};

export const useUpdateThemeApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateThemeApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateThemeApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    id: number,
    categoryId: number,
    categoryGenreId: number,
    name: string,
    ord: number,
    impressionFlg: boolean,
    description: string,
    image: string,
    documentPath: string
  ) => {
    return await callApi({
      accountOriginalFlg,
      accountId,
      id,
      categoryId,
      categoryGenreId,
      name,
      ord,
      impressionFlg,
      description,
      image,
      documentPath,
    });
  };
  return {
    isUpdating,
    callUpdateThemeApi,
  };
};

export const useUpdateThemeFixedApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateThemeFixedApi,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateThemeFixedApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    id: number,
    categoryGenreId: number,
    name: string,
    ord: number,
    description: string,
    image: string,
    documentPath: string
  ) => {
    return await callApi({
      accountOriginalFlg,
      accountId,
      id,
      categoryGenreId,
      name,
      ord,
      description,
      image,
      documentPath,
    });
  };
  return {
    isUpdating,
    callUpdateThemeFixedApi,
  };
};

export const useUpdateContentsApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateContentsApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateContentsApi = async (params: apiUpdateContents) => {
    return await callApi(params);
  };
  return {
    isUpdating,
    callUpdateContentsApi,
  };
};

export const useUpdateContentsWorkApi = () => {
  const { isExecuting: isUpdating, callApi: callUpdateContentsWorkApi } = useTokenApi({
    api: updateContentsWorkApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isUpdating,
    callUpdateContentsWorkApi,
  };
};

export const useUpdateContentsMediaApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateContentsMediaApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateContentsMediaApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    id: number,
    name: string,
    contentsMediaConvertId: number,
    playtime: number,
    thumbnailType: string,
    thumbnailPath: string
  ) => {
    return await callApi({
      accountOriginalFlg,
      accountId,
      id,
      name,
      contentsMediaConvertId,
      playtime,
      thumbnailType,
      thumbnailPath,
    });
  };
  return {
    isUpdating,
    callUpdateContentsMediaApi,
  };
};

export const useUpdateTestTemplateApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateTestTemplateApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateTestTemplateApi = async (testTemplates: apiLoadTestItem) => {
    return await callApi(testTemplates);
  };
  return {
    isUpdating,
    callUpdateTestTemplateApi,
  };
};

export const useUpdateTestTemplateWorkApi = () => {
  const { isExecuting: isUpdating, callApi: callUpdateTestTemplateWorkApi } = useTokenApi({
    api: updateTestTemplateWorkApi,
    customErrorHandling: errorMessageHandling,
  });

  return {
    isUpdating,
    callUpdateTestTemplateWorkApi,
  };
};

export const useUpdateStudyGroupApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateStudyGroupApi,
    customErrorHandling: errorMessageHandling,
  });
  const callUpdateStudyGroupApi = async (id: number, name: string, accountId?: string) => {
    return await callApi({ id, name, accountId });
  };
  return {
    isUpdating,
    callUpdateStudyGroupApi,
  };
};

export const useUpdateLegalCurriculumApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateLegalCurriculumApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateLegalCurriculumApi = async (
    id: number,
    legalCurriculumTemplateId: number,
    studyGroupId: number,
    dtls: { month: number; themeId: number; monthOrd: number; themeOrd: number }[],
    accountId?: string
  ) => {
    return await callApi({ id, legalCurriculumTemplateId, studyGroupId, dtls, accountId });
  };
  return {
    isUpdating,
    callUpdateLegalCurriculumApi,
  };
};

export const useUpdateAnnounceApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateAnnounceApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateAnnounceApi = async (
    id: number,
    announceType: announceType,
    htmlFlg: boolean | number,
    importantFlg: boolean | number,
    title: string,
    contents: string,
    target: announceTargetType,
    startDate: Date | string | null,
    bannerEndDate: Date | string | null,
    fileObjectKey: string,
    fileName: string | undefined,
    linkLabel: string | undefined
  ) => {
    return await callApi({
      id,
      announceType,
      htmlFlg,
      importantFlg,
      title,
      contents,
      target,
      startDate,
      bannerEndDate,
      fileObjectKey,
      fileName,
      linkLabel,
    });
  };
  return {
    isUpdating,
    callUpdateAnnounceApi,
  };
};

export const useUpdateUserAdditionalInfoApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateUserAdditionalInfoApi,
  });

  const callUpdateUserAdditionalInfoApi = async (params: {
    lastName: string;
    firstName: string;
    occupation: additionalInfoOccupationType;
    experienceYear: additionalInfoExperienceYearType;
    joinedCompanyDate?: Date | string | null;
    birthDate?: Date | string | null;
  }) => {
    return await callApi(params);
  };
  return {
    isUpdating,
    callUpdateUserAdditionalInfoApi,
  };
};

export const useUpdateCategoryGenreApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateCategoryGenreApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateCategoryGenreApi = async (genres: { id: number; name: string; ord: number }[]) => {
    return await callApi({ genres });
  };
  return {
    isUpdating,
    callUpdateCategoryGenreApi,
  };
};

export const useUpdateTutorialClosedApi = () => {
  const { isExecuting: isUpdating, callApi: callUpdateTutorialClosedApi } = useTokenApiNoArgs({
    api: updateTutorialClosedApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  return {
    isUpdating,
    callUpdateTutorialClosedApi,
  };
};

export const useUpdateTutorialDisplayedApi = () => {
  const { callApi: callUpdateTutorialDisplayedApi } = useTokenApiNoArgs({
    api: updateTutorialDisplayedApi,
    // 不要な描画が走らないようbannerを使わない
    // 合わせて、useStateも利用しない
    noChangeState: true,
  });

  return {
    callUpdateTutorialDisplayedApi,
  };
};

export const useUpdateChangeLoginSuccessApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: updateChangeLoginSuccessApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdateChangeLoginSuccessApiApi = async (
    loginInfo:
      | {
          loginType: "PASSWORD";
          password: string;
        }
      | { loginType: "EMAIL"; mailAddress: string }
      | { loginType: "SMS"; phoneNumber: string }
  ) => {
    await callApi({ loginInfo });
  };
  return {
    isSaving,
    callUpdateChangeLoginSuccessApiApi,
  };
};

export const useUpdatePackageApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updatePackageApi,
    customErrorHandling: errorMessageHandling,
  });

  const callUpdatePackageApi = async (params: apiUpdatePackage) => {
    return await callApi(params);
  };
  return {
    isUpdating,
    callUpdatePackageApi,
  };
};

export const useUpdateAccountNoticeApi = () => {
  const { isExecuting: isUpdating, callApi } = useTokenApi({
    api: updateAccountNoticeApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callUpdateAccountNoticeApi = async (params: apiUpdateAccountNotice) => {
    return await callApi(params);
  };
  return {
    isUpdating,
    callUpdateAccountNoticeApi,
  };
};

export const useDeleteBookmarkApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteBookmarkApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteBookmarkApi = async (accountOriginalFlg: boolean, contentsId: number) => {
    return await callApi({ accountOriginalFlg, contentsId });
  };
  return {
    isDeleting,
    callDeleteBookmarkApi,
  };
};

export const useDeleteAccountApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteAccountApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteAccountApi = async (id: string) => {
    return await callApi({ id });
  };
  return {
    isDeleting,
    callDeleteAccountApi,
  };
};

export const useDeleteCorporationAccountApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteCorporationAccountApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteCorporationAccountApi = async (id: string) => {
    return await callApi({ id });
  };
  return {
    isDeleting,
    callDeleteCorporationAccountApi,
  };
};

export const useDeleteUserApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteUserApi,
    customErrorHandling: errorMessageHandling,
  });

  const callDeleteUserApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isDeleting,
    callDeleteUserApi,
  };
};

export const useDeleteCategoryApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteCategoryApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteCategoryApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    id: number | string
  ) => {
    return await callApi({ accountOriginalFlg, accountId, id: id as number });
  };
  return {
    isDeleting,
    callDeleteCategoryApi,
  };
};

export const useDeleteThemeApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteThemeApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteThemeApi = async (accountOriginalFlg: boolean, accountId: string | undefined, id: number) => {
    return await callApi({ accountOriginalFlg, accountId, id });
  };
  return {
    isDeleting,
    callDeleteThemeApi,
  };
};

export const useDeleteContentsApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteContentsApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteContentsApi = async (accountOriginalFlg: boolean, accountId: string | undefined, id: number) => {
    return await callApi({ accountOriginalFlg, accountId, id });
  };
  return {
    isDeleting,
    callDeleteContentsApi,
  };
};

export const useDeleteContentsWorkApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteContentsWorkApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteContentsWorkApi = async (accountOriginalFlg: boolean, accountId: string | undefined, id: number) => {
    return await callApi({ accountOriginalFlg, accountId, id });
  };
  return {
    isDeleting,
    callDeleteContentsWorkApi,
  };
};

export const useDeleteTestTemplateWorkApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteTestTemplateWorkApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteTestTemplateWorkApi = async (testTemplateWorkId: number) => {
    return await callApi({ testTemplateWorkId });
  };
  return {
    isDeleting,
    callDeleteTestTemplateWorkApi,
  };
};

export const useDeleteContentsMediaApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteContentsMediaApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteContentsMediaApi = async (
    accountOriginalFlg: boolean,
    accountId: string | undefined,
    id: number | string
  ) => {
    return await callApi({ accountOriginalFlg, accountId, id });
  };
  return {
    isDeleting,
    callDeleteContentsMediaApi,
  };
};

export const useDeleteStudyGroupApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteStudyGroupApi,
    customErrorHandling: errorMessageHandling,
  });
  const callDeleteStudyGroupApi = async (id: number, accountId?: string) => {
    return await callApi({ id, accountId });
  };
  return {
    isDeleting,
    callDeleteStudyGroupApi,
  };
};

export const useDeleteLegalCurriculumApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteLegalCurriculumApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteLegalCurriculumApi = async (id: number, accountId?: string) => {
    return await callApi({ id, accountId });
  };
  return {
    isDeleting,
    callDeleteLegalCurriculumApi,
  };
};

export const useDeleteLegalCurriculumStudiedForceApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteLegalCurriculumStudiedForceApi,
    customErrorHandling: useErrorBannerHandling(),
  });
  const callDeleteLegalCurriculumStudiedForceApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isDeleting,
    callDeleteLegalCurriculumStudiedForceApi,
  };
};

export const useDeleteCareerExclusionApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteCareerExclusionApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteCareerExclusionApi = async (
    studyGroupId: number,
    careerType: careerType | string,
    level: number,
    accountId: string | undefined
  ) => {
    return await callApi({ studyGroupId, careerType: careerType as careerType, level, accountId });
  };
  return {
    isDeleting,
    callDeleteCareerExclusionApi,
  };
};

export const useDeleteAnnounceApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteAnnounceApi,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteAnnounceApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isDeleting,
    callDeleteAnnounceApi,
  };
};

export const useDeleteCategoryGenreApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deleteCategoryGenreApi,
    customErrorHandling: errorMessageHandling,
  });

  const callDeleteCategoryGenreApi = async (id: number) => {
    return await callApi({ id });
  };
  return {
    isDeleting,
    callDeleteCategoryGenreApi,
  };
};

export const useDeleteInviteApi = () => {
  const { isExecuting: isSaving, callApi } = useTokenApi({
    api: deleteInvite,
    customErrorHandling: useErrorBannerHandling(),
  });

  const callDeleteInviteApi = async (inviteUlid: string) => {
    return await callApi({ inviteUlid });
  };
  return {
    isSaving,
    callDeleteInviteApi,
  };
};

export const useDeletePersonnelChangeApi = () => {
  const { isExecuting: isDeleting, callApi } = useTokenApi({
    api: deletePersonnelChangeApi,
    customErrorHandling: errorMessageHandling,
  });

  const callDeletePersonnelChangeApi = async (personnelChangeLogId: number) => {
    return await callApi({ personnelChangeLogId });
  };
  return {
    isDeleting,
    callDeletePersonnelChangeApi,
  };
};
